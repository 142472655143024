import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { sharedStyles } from "../../assets/shared_styles"
import { appColors } from "../../assets/app_colors"
import { breakpoints, lineCharacterLimits } from "../../assets/app_consts"
import { mq } from "../../assets/shared_styles"
import { useHistory } from "react-router"
import { getImageSrc } from "../../util/getImageUrl"
import {
  calculateBoxShadowColor,
  getButtonText,
  calculateExplorerCardDimensions,
} from "../../util/component_utils"
import { appStrings } from "../../assets/app_strings"
import { gtmTypes, gtmId } from "../../util/gtm_utils"

const {
  explorerFeaturedCardBorder,
  whiteText,
  explorerCardBackground,
  chapterExplorerCardBackground,
  explorerCardLabel,
  hoverChapterSelectorCardBorder,
} = appColors
const { desktopStandard, tabletChapterSelector } = breakpoints
const { chapterSelectorCardHeader } = lineCharacterLimits
const { buttonStyler } = sharedStyles
const { cardLabel } = appStrings.explorer
const { explorerModuleCard, explorerChapterCard } = gtmTypes

// todo: refactor so cardContent can use a util function to pull data from asset
const cardContent = (
  title,
  description,
  handleShowMediaModal,
  isModuleCard,
  asset,
  history,
  windowSize,
  route,
  chapterLabel,
  isAdvancedTopic,
) => {
  const { slug, altText, uid, whiteList } = asset
  const isMobile = windowSize < desktopStandard
  const buttonText = getButtonText(asset, isModuleCard, isAdvancedTopic)
  const gtmType = isModuleCard ? explorerModuleCard : explorerChapterCard

  // todo: for board demo
  const isBlocked = whiteList !== null && whiteList !== uid

  const handleCardClick = () => {
    if (isModuleCard) {
      window.history.pushState(null, "", route + window.location.search)
      handleShowMediaModal(asset)
    } else {
      if (isBlocked) {
        alert("Coming Soon")
      } else {
        history.push(route + window.location.search)
      }
    }
  }

  return isMobile ? (
    <button
      className="card card-expanded pointer no-child-pointer-events"
      onClick={handleCardClick}
      id={gtmId(gtmType, slug)}
    >
      <div className="card-image" role="img" aria-label={altText} />
      <div className="module-info">
        <div className="module-content">
          {!isModuleCard && <p className="label-text">{chapterLabel}</p>}
          <h3 className="demi">{title}</h3>
          <p className="description">{description}</p>
        </div>
        <div className="button-link">{buttonText}</div>
      </div>
    </button>
  ) : (
    <button
      className="card card-contracted pointer no-child-pointer-events"
      onClick={handleCardClick}
      id={gtmId(gtmType, slug)}
    >
      <div className="card-image" role="img" aria-label={altText} />
      <div className="module-info">
        <div className="visible">
          {!isModuleCard && <p className="label-text">{chapterLabel}</p>}
          <h3 className="demi">{title}</h3>
          <div className="description">{description}</div>
        </div>
        <div className="button-link">{buttonText}</div>
      </div>
    </button>
  )
}

const ExplorerCard = ({
  asset,
  themeColor,
  isModuleCard,
  handleShowMediaModal,
  windowSize,
  ctaColor,
  isAdvancedTopic,
}) => {
  const {
    cardImage: chapterCardImage,
    number,
    title,
    description,
    image: modCardImage,
    route,
  } = asset
  const chapterLabel = cardLabel(number, isAdvancedTopic)
  const cardImage = isModuleCard ? modCardImage : chapterCardImage
  const backgroundColor = isModuleCard
    ? chapterExplorerCardBackground
    : explorerCardBackground
  const history = useHistory()
  const cardImageSize = calculateExplorerCardDimensions(windowSize)
  const useSmallerFont = isModuleCard
    ? title.length >= chapterSelectorCardHeader * 3
    : title.length >= chapterSelectorCardHeader * 2

  return (
    <ExplorerCardContainer
      className="explorer-card"
      cardImage={getImageSrc(cardImage, cardImageSize)}
      themeColor={themeColor}
      buttonColor={ctaColor}
      isModuleCard={isModuleCard}
      backgroundColor={backgroundColor}
      useSmallerFont={useSmallerFont}
    >
      {cardContent(
        title,
        description,
        handleShowMediaModal,
        isModuleCard,
        asset,
        history,
        windowSize,
        route,
        chapterLabel,
        isAdvancedTopic,
      )}
    </ExplorerCardContainer>
  )
}

ExplorerCard.propTypes = {
  asset: PropTypes.object,
  themeColor: PropTypes.string,
  isModuleCard: PropTypes.bool,
}

const ExplorerCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: ${tabletChapterSelector}px;

  .card {
    
    // remove button styling
    text-align: inherit;
    padding: inherit;
    border: none;

    border-bottom: 5px solid;
    min-height: 345px;


    // a {
    // text-decoration:none;
    // }

    // h3 a{
    //   color:black;
    // }

    &-image {
      background-image: url(${props => props.cardImage});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
    }

    &-expanded {
      border-bottom: 5px solid ${explorerFeaturedCardBorder};

      .card-image {
        height: 286px;
      }
      .module-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .module-content {
          margin-bottom: 1rem;
          .description {
            min-height: 95px;
          }
        }
      }
      h3 {
        line-height: calc(100% + 6px);
      }

      .button-link {
        ${props => {
          const { backgroundColor, buttonColor } = props

          return buttonStyler(backgroundColor, buttonColor, false)
        }}
      }
    }

    &-contracted {
      .button-link {
        ${props => {
          const { backgroundColor, themeColor } = props

          return buttonStyler(backgroundColor, themeColor, false)
        }}
      }
    }

    .label-text:first-child {
      color: ${explorerCardLabel};
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-text {
      margin-bottom: 1em;
    }

    .label-text {
      font-size: 0.75em;
    }

    .description {
      font-size: 0.875em;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }

  .module-info {
    width: 100%;
    padding: 28px;
    background: ${props =>
      props.isModuleCard
        ? chapterExplorerCardBackground
        : explorerCardBackground};
  }

  ${mq.newerPhoneStandard`
    .card {
      &-contracted {
        height: 345px;
        overflow: hidden;
        border-color: ${explorerFeaturedCardBorder};
        box-shadow: 0px 10px 15px 0px ${calculateBoxShadowColor(
          explorerFeaturedCardBorder,
          95,
        )};
        transition-duration: 400ms;

        .card-image {
          height: 200px;
        }
        .module-info {
          height: 345px;
          padding: 32px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          top: 0;
          transition-duration: 400ms;

          .visible {
            h3 {
              min-height: 108px;
              margin: 0 0 30px 0;
            }
          }
        }

        &:hover {
          box-shadow: 0px 10px 15px 0px ${props =>
            calculateBoxShadowColor(props.themeColor, 95)};
          border-color: ${hoverChapterSelectorCardBorder};
          background: ${props => props.themeColor};

          .module-info {
            top: -200px;
            background: ${props => props.themeColor};
            color: ${whiteText};

            .visible {
              h3 {
                min-height: 26px;
              }
            }
            .description, .label-text {
              color: ${whiteText};
            }
          }
        }
      }
    }
  `}

  ${mq.desktopStandard`
    max-width: 365px;
    min-height: 345px;

    .module-info {
      .visible {
        h3 {
          font-size: ${props => (props.useSmallerFont ? "1" : "1.17")}em;
        }
      }
    }
  `}

  ${mq.desktopExplorer`
    .module-info {
      .visible {
        h3 {
          font-size: 1.17em;
        }
      }
    }
  `}
`

export default ExplorerCard
