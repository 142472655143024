import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ContactUs from "../shared/contact_us"
import { appColors } from "../../assets/app_colors"
import { appStrings } from "../../assets/app_strings"
import { mq, sharedStyles } from "../../assets/shared_styles"
import { getImageSrc } from "../../util/getImageUrl"
import FooterContactOverride from "./footer_contact_override"

const { whiteText } = appColors
const { linkCTA } = appStrings.heroFooter
const { scalingFontSize } = sharedStyles

const HeroFooter = ({ footer, phoneNumber, email, isMobile }) => {
  const {
    footerImage,
    footerImageMobile,
    footerHeader,
    footerSubheader,
    footerImageAltText: altText,
    contactUsOptionalText,
    contactUsOverrideHtml,
  } = footer
  const mobileImageUrl = footerImageMobile
    ? getImageSrc(footerImageMobile, 800)
    : getImageSrc(footerImage, 800)
  const imageUrl = isMobile ? mobileImageUrl : getImageSrc(footerImage, 1800)

  return (
    <HeroFooterContainer footerImage={imageUrl}>
      <div role="img" aria-label={altText} />
      <h1 className="medium">{footerHeader}</h1>
      <p className="light">{footerSubheader}</p>
      <div className="hero-footer-contact">
        {contactUsOverrideHtml ? (
          <FooterContactOverride
            contactUsOverrideHtml={contactUsOverrideHtml}
          />
        ) : (
          <ContactUs
            phoneNumber={phoneNumber}
            email={email}
            isFooter={true}
            contactUsOptionalText={contactUsOptionalText}
          />
        )}
        <div className="button-link">{linkCTA}</div>
      </div>
    </HeroFooterContainer>
  )
}

HeroFooter.propTypes = {
  footerImage: PropTypes.string,
  footerSubheader: PropTypes.string,
  phoneNumber: PropTypes.string,
}

const HeroFooterContainer = styled.div`
  background-image: url(${props => props.footerImage});
  background-size: cover;
  background-repeat: no-repeat;
  color: ${whiteText};
  padding: 16.125rem 2.25rem 3.125rem 2.25rem;

  h1 {
    ${scalingFontSize(50, 64)}
    text-transform: uppercase;
    margin: 0;
  }
  p.light {
    margin-bottom: 0;
    font-size: 22px;
  }

  ${mq.tabletTopicDetail`
    padding: 13.125rem 9rem 6.875rem 9rem;
    p, .contact {
      margin-left: 0.5em;
    }
  `};

  ${mq.tabletStandard`
    h1 {
      ${scalingFontSize(64, 96)}
    }
  `}

  ${mq.desktopStandard`
    h1 {
      font-size: 5em;
    }
  `}
`

export default HeroFooter
