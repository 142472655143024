import React, {useState, useEffect} from 'react'
import styled, { css } from 'styled-components'
import { mq } from "../../assets/shared_styles"
import { breakpoints } from "../../assets/app_consts"

const subStringBetween = (s, a, b) => {
  if (s.length === 0) {
    return ''
  }
  const p = s.indexOf(a) + a.length;
  return s.substring(p, s.indexOf(b, p));
}

const getMarkup = (html) => {
  const dynamicStyles = subStringBetween(html, '__MQ_START__', "__MQ_END__")
  const noDynamicStyles = html.substring(0, html.length).replace(dynamicStyles, '')
  return { __html: noDynamicStyles }
}

const mqs = (html) => {
  const dynamicStyles = subStringBetween(html, '__MQ_START__', "__MQ_END__")
  const mqBreakpoints = Object.keys(breakpoints)
  const mqEnd = "__END__"
  return (
    mqBreakpoints.reduce((a, c) => {
      if (dynamicStyles.includes(c)) {
        a[c] = subStringBetween(dynamicStyles, c, mqEnd)
      }
      return a
    }, {})
  )
}

export const ConfigableTopic = ({ html }) => {
  const [textHtml, setTextHtml] = useState(html)

  useEffect(() => {
    setTextHtml(html)
  }, [html])

  return (
    <ParentDiv
      parentStyles={subStringBetween(textHtml, '__PARENT_DIV_START__', '__PARENT_DIV_END__')}
      mqs={mqs(textHtml)}
      dangerouslySetInnerHTML={getMarkup(textHtml)}>
    </ParentDiv>
  )
}

const ParentDiv = styled.div(
  props => css`
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${props.parentStyles}

  ${mq.newerPhoneStandard`
    ${props.mqs.newerPhoneStandard}
  `}

  ${mq.tabletNavigationMenu`
    ${props.mqs.tabletNavigationMenu}
  `}

  ${mq.tabletChapterSelector`
    ${props.mqs.tabletTopicDetail}
  `}
 
  ${mq.tabletTopicDetail`
    ${props.mqs.tabletTopicDetail}
  `}

  ${mq.tabletExplorer`
    ${props.mqs.tabletExplorer}
  `}

  ${mq.tabletStandard`
    ${props.mqs.tabletStandard}
  `}

  ${mq.desktopStandard`
    ${props.mqs.desktopStandard}
  `}

  ${mq.desktopExplorer`
    ${props.mqs.desktopExplorer}
  `}
  `
)