import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FixedBottom } from "react-fixed-bottom"
import NavigationMenu from "../components/navigation_menu/navigation_menu"
import LandingHeroBanner from "../components/landing_hero_banner/landing_hero_banner"
import TopicDetail from "../components/topic_detail/topic_detail"
import Explorer from "../components/explorer/explorer"
import Footer from "../components/footer/footer"
import TagManager from "react-gtm-module"
import { getGTM } from "../util/gtm_utils"

const TopicLandingPage = ({
  partner,
  topic,
  getDocument,
  settings,
  topics,
  isPublic,
  handleShowMediaModal,
  selectedModuleFromUrl,
  publicModules,
  homeRoute,
  homeSlug
}) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const updateWindowSize = () => {
    setWindowSize(window.innerWidth)
  }

  useEffect(() => {
    if (selectedModuleFromUrl) {
      handleShowMediaModal(selectedModuleFromUrl)
    }
  }, [selectedModuleFromUrl, handleShowMediaModal])

  // on component mount
  useEffect(() => {
    TagManager.dataLayer(getGTM())
    window.addEventListener("resize", updateWindowSize)
    return () => window.removeEventListener("resize", updateWindowSize)
  }, [])

  const toggleNavOpen = () => setNavOpen(!isNavOpen)

  const {
    logo: logoUrl,
    logoAltText,
    themeColor,
    phoneNumber,
    email,
    footerText,
    logoLink,
    title: partnerName,
    contactUsOptionalText,
    contactUsOverrideHtml,
    footerSubheader,
  } = partner
  const {
    chapters: chapterUids,
    title: topicTitle,
    subTitle,
    heroImage,
    heroImageMobile,
    topicAltText,
    detailHeader,
    detailSubheader,
    icon,
    isAdvancedTopic,
  } = topic
  const {
    blockColor,
    footerLogo,
    footerImage,
    footerLogoAltText,
    footerImageAltText,
    footerImageMobile,
    footerHeader,
    baseColor,
    mfinTheme,
    privacyUrl,
  } = settings

  const footer = {
    footerLogo,
    footerImage,
    footerImageMobile,
    footerHeader,
    footerSubheader,
    privacyUrl,
    footerLogoAltText,
    footerImageAltText,
    contactUsOptionalText,
    contactUsOverrideHtml,
  }

  return (
    <TopicLandingPageContainer>
      <NavigationMenu
        iconOnClick={toggleNavOpen}
        baseColor={baseColor}
        getDocument={getDocument}
        topics={topics}
        phoneNumber={phoneNumber}
        email={email}
        partnerLogo={logoUrl}
        altText={logoAltText}
        isPublic={isPublic}
        isMenuOpen={isNavOpen}
        windowSize={windowSize}
        partnerLogoLink={logoLink}
        homeRoute={homeRoute}
        homeSlug={homeSlug}
      />
      <FixedBottom offset={0}>
        <div className="body">
          <LandingHeroBanner
            title={topicTitle}
            description={subTitle}
            chapterUids={chapterUids}
            heroImageUrl={heroImage}
            heroImageMobileUrl={heroImageMobile}
            altText={topicAltText}
            getDocument={getDocument}
            baseColor={baseColor}
            themeColor={themeColor}
            isNavOpen={isNavOpen}
            toggleNavOpen={toggleNavOpen}
            windowSize={windowSize}
            isPublic={isPublic}
            isAdvancedTopic={isAdvancedTopic}
          />
          {!isPublic && (
            <TopicDetail
              detailHeader={detailHeader}
              detailSubheader={detailSubheader}
              icon={icon}
              blockColor={blockColor}
            />
          )}
          <Explorer
            themeColor={themeColor}
            ctaColor={mfinTheme}
            topicTitle={topicTitle}
            blockColor={blockColor}
            chapterUids={chapterUids}
            getDocument={getDocument}
            isPublic={isPublic}
            handleShowMediaModal={handleShowMediaModal}
            publicModules={publicModules}
            windowSize={windowSize}
            isAdvancedTopic={isAdvancedTopic}
          />
          <Footer
            footer={footer}
            footerText={footerText}
            phoneNumber={phoneNumber}
            email={email}
            baseColor={baseColor}
            windowSize={windowSize}
            partnerName={partnerName}
            isPublic={isPublic}
          />
        </div>
      </FixedBottom>
    </TopicLandingPageContainer>
  )
}

const TopicLandingPageContainer = styled.section`
  display: flex;
  flex-direction: column;
`
export default TopicLandingPage
