import React from "react"
import PropTypes from "prop-types"
import ExplorerCard from "./explorer_card"
import { ExplorerContainer } from "../explorer/explorer"
import { ConfigableTopic } from '../configable_topic/configable_topic'


const ChapterExplorer = ({
    blockColor,
    chapter: chap,
    partner,
    handleShowMediaModal,
    topicTitle,
    ctaColor,
    getDocument,
    windowSize,
    AdvancedTopicShare,
}) => {
    const chapter = getDocument("chapters", "uid", chap.uid)
    const { modules, header, description, title: chapterTitle } = chapter
    const { themeColor } = partner
    const isModuleCard = true
    const { advTopics = '' } = chap

    const renderExplorerCards = () => {
        return modules.map(module => {
            const { uid, route } = module
            return (
                <ExplorerCard
                    key={uid}
                    asset={module}
                    isModuleCard={isModuleCard}
                    themeColor={themeColor}
                    ctaColor={ctaColor}
                    handleShowMediaModal={handleShowMediaModal}
                    chapterTitle={chapterTitle}
                    topicTitle={topicTitle}
                    route={route}
                    windowSize={windowSize}
                />
            )
        })
    }

    return (
        <ExplorerContainer blockColor={blockColor} isTopicExplorer={false}>
            {!!advTopics ?
            <>
              <AdvancedTopicShare position={'top'} />
              <ConfigableTopic html={advTopics} />
              <AdvancedTopicShare position={'bottom'} />
            </>
            :
            <>
            <div className="chapter-explorer-header">
                <h1 className="demi">{header}</h1>
                <p>{description}</p>
            </div>
            <div className="card-wrapper">{renderExplorerCards()}</div>
            </>
            }
        </ExplorerContainer>
    )
}


ChapterExplorer.propTypes = {
    chapter: PropTypes.object,
    getDocument: PropTypes.func,
}

export default ChapterExplorer
