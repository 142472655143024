const gtmId = (type, id, slug = null) =>
  slug === null
    ? `GTM-CLICK-ITEM:${type}:${id}`
    : `GTM-CLICK-ITEM:${type}:${id}:${slug}`

const gtmTypes = {
  heroChapterSelectorCard: "heroChapterSelectorCard",
  navMenuIcon: "navMenuIcon",
  navMenuTopicLink: "navMenuTopicLink",
  navMenuExpandedTopicLink: "navMenuExpandedTopicLink",
  navMenuChapterLink: "navMenuChapterLink",
  navBarPartnerLogoHomeLink: "navBarPartnerLogoHomeLink",
  explorerModuleCard: "explorerModuleCard",
  explorerChapterCard: "explorerChapterCard",
  explorerFeaturedCard: "explorerFeaturedCard",
  footerPrivacyLink: "footerPrivacyLink",
  hamburgerMenuIcon: "hamburgerMenuIcon",
  modalShareButton: "modalShareButton",
  shareMenuCopyLink: "shareMenuCopyLink",
  shareMenuEmailLink: "shareMenuEmailLink",
  shareMenuCloseMenu: "shareMenuCloseMenu",
  shareMenuTwitter: "shareMenuTwitter",
  shareMenuLinkedIn: "shareMenuLinkedIn",
  shareMenuFacebook: "shareMenuFacebook",
  chapterCarouselControlRight: "chapterCarouselControlRight",
  chapterCarouselControlLeft: "chapterCarouselControlLeft",
  partnerLogoLink: "partnerLogoLink",
  modalDownloadPdfButton: "modalDownloadPdfButton",
  modalPrintPdfButton: "modalPrintPdfButton",
}

const getGTM = () => ({
  dataLayer: {
    page: window.document.location.pathname,
    event: "pageView",
  },
})

export { gtmId, gtmTypes, getGTM }
