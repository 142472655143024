import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { gtmId, gtmTypes } from "../../util/gtm_utils"
import { mq } from "../../assets/shared_styles"

const gtmType = gtmTypes.partnerLogoLink

const Branding = ({ partnerLogo, className, altText }) => {
  return (
    <BrandingContainer className={className}>
      <img
        src={partnerLogo}
        alt={altText}
        id={gtmId(gtmType, "partner-url")}
      />
    </BrandingContainer>
  )
}

Branding.propTypes = {
  partnerLogo: PropTypes.string,
}

const BrandingContainer = styled.div`
  max-height: 50px;
  img {
    max-width: 70vw;
    max-height: inherit;
  }
  ${mq.tabletStandard`
    max-height: 75px;
  `}
`

export default Branding
