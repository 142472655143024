import React, { useEffect, useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { mq } from "../../assets/shared_styles"
import Branding from "./branding"
import ContactUs from "./contact_us"
import { gtmId, gtmTypes } from "../../util/gtm_utils"
import Icon from "../shared/icon"
import { useHistory } from "react-router"

const { whiteText } = appColors
const { navMenuIcon: gtmType, navBarPartnerLogoHomeLink: gtmTypeLogo } =
  gtmTypes

const NavigationRow = ({
  partnerLogo,
  altText,
  iconOnClick,
  baseColor,
  isPublic,
  phoneNumber,
  isMenuOpen,
  email,
  homeRoute,
  homeSlug,
  partnerLogoLink,
}) => {
  const renderNewRightNav = useCallback(() => {
    if (isPublic) {
      return (
        <ContactUs
          phoneNumber={phoneNumber}
          email={email}
          isFooter={false}
          isNavbar={true}
        />
      )
    }

    return (
      <button
        className="menu-wrapper pointer no-child-pointer-events"
        onClick={() => iconOnClick()}
        id={gtmId(gtmType, "toggle-nav-menu")}
      >
       
          {/* <span>
            {isMenuOpen ? (
              <Icon name="exit" color={whiteText} />
            ) : (
              <Icon name="menu" color={whiteText} />
            )}
          </span> */}
          <Icon name={isMenuOpen ? "exit" : "menu"} color={whiteText} />
      </button>
    )
  }, [isMenuOpen, email, iconOnClick, isPublic, phoneNumber])

  useEffect(() => {
    renderNewRightNav()
  }, [isMenuOpen, renderNewRightNav])

  const history = useHistory()

  const handleHomeClick = () => {
    if (partnerLogoLink) {
      window.open(partnerLogoLink, "_blank")
    } else {
      history.push(homeRoute + window.location.search)
    }
  }

  return (
    <NavigationRowContainer baseColor={baseColor} textColor={whiteText}>
      <div
        className="no-child-pointer-events pointer"
        id={gtmId(gtmTypeLogo, homeSlug)}
        onClick={handleHomeClick}
      >
        <Branding partnerLogo={partnerLogo} altText={altText} />
      </div>
      {renderNewRightNav()}
    </NavigationRowContainer>
  )
}

NavigationRow.propTypes = {
  partnerLogo: PropTypes.string,
  toggleNavOpen: PropTypes.func,
}
const NavigationRowContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.baseColor};
  padding: 1.375em 1.125rem;

  .partner-number {
    color: ${(props) => props.textColor};
    margin: 0;
    font-weight: bold;
    font-size: 13px;
  }
  .nav-menu {
    span {
      display: none;
      color: ${whiteText};
      font-size: 18px;
    }
    svg {
      display: flex;
    }
  }
  button{
    background:transparent;
    border:none;
  }
  ${mq.desktopStandard`
    .nav-menu {
      span {
        display: inherit;
      }
      svg {
        display: none;
      }
      span > svg {
          display: flex;
        }
    }
  `}

  ${mq.tabletStandard`
    height: 90px;
  `}

  ${mq.newerPhoneStandard`
    padding: 0 1.875rem;
  `}
`
export default NavigationRow
