import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { appColors } from "../../assets/app_colors"
import { gtmId, gtmTypes } from "../../util/gtm_utils"
import { mq } from "../../assets/shared_styles"
import { useHistory } from "react-router"
import { calculateIfFontShouldBeSmaller } from "../../util/component_utils"
import { lineCharacterLimits } from "../../assets/app_consts"

const {
  darkText,
  neutralChapterSelectorCard,
  neutralChapterSelectorCardBorder,
  neutralChapterSelectorChapterNumber,
} = appColors
const gtmType = gtmTypes.heroChapterSelectorCard

const ChapterSelectorCard = ({ chapter, themeColor, isHighlighted, cardNumberText }) => {
  const { title, icon, slug, route, whiteList, uid } = chapter
  const history = useHistory()

  // todo: for board demo
  const isBlocked = whiteList !== null && whiteList !== uid

  const handleLink = () => {
    if (isBlocked) {
      alert("Coming Soon")
    } else {
      history.push(route + window.location.search)
    }
  }

  const useSmallerFont = calculateIfFontShouldBeSmaller(
    title,
    lineCharacterLimits.chapterSelectorCardHeader,
    3,
  )
  const className = `no-child-pointer-events pointer selector-card ${
    isHighlighted ? "highlighted" : ""
  }`
  return (
    <ChapterSelectorCardContainer
      hoverColor={themeColor}
      id={gtmId(gtmType, slug)}
      onClick={handleLink}
      className={className}
      useSmallerFont={useSmallerFont}
      href="#"
    >
      <div className="header">
        <div>
          <img src={icon} alt="chapter icon" />
          <p className="chapter-number">{cardNumberText}</p>
        </div>
        <Icon icon={IconNames.ARROW_TOP_RIGHT} iconSize={18} />
      </div>
      <h2 className="chapter-title demi">{title}</h2>
    </ChapterSelectorCardContainer>
  )
}

ChapterSelectorCard.propTypes = {
  chapter: PropTypes.object,
  themeColor: PropTypes.string,
}

const ChapterSelectorCardContainer = styled.a`

  // remove a styling
  display: block;
  text-decoration: none;

  // small phones+
  width: 226px;
  height: 190px;
  padding: 2em;
  background: ${neutralChapterSelectorCard};
  border-bottom: 5px solid ${neutralChapterSelectorCardBorder};
  color: ${darkText};
  transition-property: background, height, border-color;
  transition-duration: 0.5s;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: color, 0.5s;

    img {
      height: 53px;
      width: 53px;
      transition: height, 0.5s;
      display: none;
    }

    p.chapter-number {
      text-align: left;
      color: ${neutralChapterSelectorChapterNumber};
      transition: margin-top 0.5s;
      font-size: 0.875em;
    }

    .bp3-icon {
      display: block;

      path {
        fill: ${darkText};
      }
    }
  }

  h2 {
    font-size: ${props => (props.useSmallerFont ? 1.1 : 1.25)}em;
    line-height: 1.27;
  }

  // normal phones+
  ${mq.newerPhoneStandard`
    .header {
      img {
        height: 0;
        width: 53px;
        opacity: 0;
      }
    }
  `}
`

export default ChapterSelectorCard
