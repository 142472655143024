import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FixedBottom } from "react-fixed-bottom"
import NavigationMenu from "../components/navigation_menu/navigation_menu"
import ChapterHeroBanner from "../components/chapter_hero_banner/chapter_hero_banner"
import Footer from "../components/footer/footer"
import ChapterExplorer from "../components/explorer/chapter_explorer"
import TagManager from "react-gtm-module"
import { getGTM } from "../util/gtm_utils"
import { ShareRow } from "../components/shared/share_row"

const ChapterLandingPage = ({
  partner,
  chapter,
  getDocument,
  selectedModuleFromUrl,
  topics,
  settings,
  handleShowMediaModal,
  topic,
  markedModules,
  homeRoute,
  homeSlug,
}) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    if (selectedModuleFromUrl) {
      handleShowMediaModal(selectedModuleFromUrl)
    }
  }, [selectedModuleFromUrl, handleShowMediaModal])

  const updateWindowSize = () => {
    setWindowSize(window.innerWidth)
  }

  useEffect(() => {
    TagManager.dataLayer(getGTM())
    window.addEventListener("resize", updateWindowSize)
    return () => window.removeEventListener("resize", updateWindowSize)
  }, [])

  const toggleNavOpen = () => setNavOpen(!isNavOpen)

  const {
    title,
    subtitle,
    description,
    number,
    icon,
    pdfPath,
    pdfFileName,
    printableAssetPath,
    heroImage,
    heroImageMobile,
  } = chapter

  const {
    logo: logoUrl,
    logoAltText,
    themeColor,
    phoneNumber,
    email,
    footerText,
    logoLink,
    title: partnerName,
    contactUsOptionalText,
    contactUsOverrideHtml,
    footerSubheader,
    disablePrintAndDownload,
  } = partner
  const { title: topicTitle, isAdvancedTopic } = topic

  const {
    blockColor,
    baseColor,
    footerLogo,
    footerImage,
    footerImageMobile,
    footerHeader,
    mfinTheme,
    privacyUrl,
  } = settings

  const footer = {
    footerLogo,
    footerImage,
    footerImageMobile,
    footerHeader,
    footerSubheader,
    privacyUrl,
    contactUsOptionalText,
    contactUsOverrideHtml,
  }

  const justifyAlignment = disablePrintAndDownload ? "flex-end" : "center"

  const AdvancedTopicShare = ({ position }) => (
    <ShareRow
      title={title}
      downloadableAssetPath={pdfPath}
      fileName={pdfFileName}
      printableAssetPath={printableAssetPath}
      disablePrintAndDownload={disablePrintAndDownload}
      justifyAlignment={justifyAlignment}
      position={position}
    />
  )

  return (
    <ChapterLandingPageContainer>
      <NavigationMenu
        iconOnClick={toggleNavOpen}
        baseColor={baseColor}
        getDocument={getDocument}
        topics={topics}
        phoneNumber={phoneNumber}
        email={email}
        partnerLogo={logoUrl}
        altText={logoAltText}
        isMenuOpen={isNavOpen}
        windowSize={windowSize}
        partnerLogoLink={logoLink}
        homeRoute={homeRoute}
        homeSlug={homeSlug}
      />
      <FixedBottom offset={0}>
        <div className="body">
          <ChapterHeroBanner
            title={title}
            subtitle={subtitle}
            number={number}
            heroImage={icon}
            description={description}
            getDocument={getDocument}
            baseColor={baseColor}
            themeColor={themeColor}
            isNavOpen={isNavOpen}
            toggleNavOpen={toggleNavOpen}
            isAdvancedTopic={isAdvancedTopic}
            advHeroImage={heroImage}
            advHeroImageMobile={heroImageMobile}
          />
          <ChapterExplorer
            getDocument={getDocument}
            themeColor={themeColor}
            ctaColor={mfinTheme}
            blockColor={blockColor}
            chapter={chapter}
            partner={partner}
            handleShowMediaModal={handleShowMediaModal}
            topicTitle={topicTitle}
            windowSize={windowSize}
            markedModules={markedModules}
            AdvancedTopicShare={AdvancedTopicShare}
          />
          <Footer
            footer={footer}
            footerText={footerText}
            phoneNumber={phoneNumber}
            email={email}
            baseColor={baseColor}
            windowSize={windowSize}
            partnerName={partnerName}
          />
        </div>
      </FixedBottom>
    </ChapterLandingPageContainer>
  )
}

const ChapterLandingPageContainer = styled.section`
  display: flex;
  flex-direction: column;
`
export default ChapterLandingPage
