import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import {
  sanitizeHTML,
  attachSanitizedAttributes,
} from "../../util/component_utils"

const { whiteText } = appColors

const FooterContactOverride = ({ contactUsOverrideHtml }) => {
  attachSanitizedAttributes()
  const sanitizedText = sanitizeHTML(contactUsOverrideHtml)

  return (
    <OptionalTextContainer>
      <p dangerouslySetInnerHTML={{ __html: sanitizedText }} />
    </OptionalTextContainer>
  )
}

FooterContactOverride.propTypes = {
  FooterContactOverride: PropTypes.string,
}

const OptionalTextContainer = styled.div`
  color: ${whiteText};
  font-family: "futura-pt", sans-serif;
  font-weight: 400;

  a {
    color: ${whiteText};
    font-weight: 700;
    text-decoration: none;
  }

`

export default FooterContactOverride
