import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { ConfigableTopic } from '../components/configable_topic/configable_topic'

export const DevAdvancedTopic = () => {
  const baseStyles = `
  <head>
  <style>
    __PARENT_DIV_START__
    __PARENT_DIV_END__
    __MQ_START__  
    __MQ_END__
  </style>
  </head>
`

  const [html, setHtml] = useState('')
  const [isCodeVisable, setIsCodeVisable] = useState(true);
  const codeRef = useRef(html)

  const viewHtml = () => {
    setHtml(codeRef.current.firstChild.innerText)
    setIsCodeVisable(!isCodeVisable)
  }

  const trimSpaces = string => {
    return string
      .replace(/&/g, "&amp;")
      .replace(/>/g, "&gt;")
      .replace(/</g, "&lt;")
  }

  const pastePlainText = evt => {
    evt.preventDefault()
    const text = evt.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, trimSpaces(text))
  }

  const copyToClipboard = () => {
    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      const textarea = document.createElement("textarea");
      const rawText = JSON.stringify(codeRef.current.firstChild.innerText);
      textarea.textContent = rawText.substring(1, rawText.length - 1)
      // Prevent scrolling to bottom of page in Microsoft Edge.
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        // Security exception may be thrown by some browsers.
        return document.execCommand("copy");
      }
      catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      }
      finally {
        document.body.removeChild(textarea);
        window.alert('CMS format copied')
      }
    }
  }

  const Element = () => {
    return <ConfigableTopic html={html} />
  }

  const CodeButtons = () => {
    return (
      <>
        <ButtonContainer>
          <button onClick={() => viewHtml()}>
            Show/Hide
          </button>
          <button onClick={() => copyToClipboard()}>
            CMS format
          </button>
        </ButtonContainer>

      </>
    )
  }

  return (
    <DevTool
      isVisable={isCodeVisable}
    >
      <CodeButtons />
      <pre
        ref={codeRef}
        onPaste={(e) => pastePlainText(e)}
      >
        <code contentEditable={true}>
          {baseStyles}
        </code>
      </pre>
      <Rendered
        isVisable={isCodeVisable}
      >
        <Element />
      </Rendered>
    </DevTool>
  )
}

const DevTool = styled.div(
  props => css`
    pre {
      opacity: ${props.isVisable ? '1' : '0'};
      overflow: auto;
      height: calc(100vh - 2.5em);
        code:focus {
          outline: none;

        } 
      }
  `
)

const Rendered = styled.div(
  props => css`
    position: absolute;
    visibility: ${props.isVisable ? 'hidden' : 'visible'};
    top: 0px;
    left: 0px;
    overflow: auto;
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    > * {
    height: 100vh;
    padding: 3em;
    }
  `
)

const ButtonContainer = styled.div(
  props => css`
    position: relative;
    z-index: 1;
    top: 0px;
    left: 0px;
  `
)