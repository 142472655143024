import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { appStrings } from "../../assets/app_strings"
import { mq } from "../../assets/shared_styles"
import ExplorerFeaturedCard from "./explorer_featured_card"
import ExplorerCard from "./explorer_card"
import { breakpoints } from "../../assets/app_consts"
import { buildAssetProps } from "../../util/component_utils"

const { desktopStandard, tabletStandard } = breakpoints
const { header, subheader } = appStrings.explorer
const { explorerBackground, explorerCardLabel, chapterExplorerBackground } =
  appColors

const Explorer = ({
  blockColor,
  chapterUids,
  getDocument,
  topicTitle,
  themeColor,
  isPublic,
  publicModules,
  handleShowMediaModal,
  ctaColor,
  windowSize,
  isAdvancedTopic,
}) => {
  const assetMeta = {
    chapterUids,
    getDocument,
    themeColor,
    publicModules,
    handleShowMediaModal,
    isPublic,
    topicTitle,
    isAdvancedTopic,
  }

  const isTablet = windowSize < desktopStandard
  const isMobile = windowSize < tabletStandard
  let cardCount = 0
  if (chapterUids !== undefined) {
    cardCount = chapterUids.length
  }

  const renderExplorerCards = () => {
    const assets = buildAssetProps(assetMeta)
    if (!isMobile && assets.length === 1) {
      // if windowSize > 900px (tabletStandard) AND only 1 chapter
      // render a single, large card (featured)
      return assets.map((assetProps, i) => {
        return (
          <ExplorerFeaturedCard
            {...assetProps}
            index={i}
            ctaColor={ctaColor}
            windowSize={windowSize}
          />
        )
      })
    }
    if (isTablet || isPublic || assets.length === 3) {
      // render only standard explorer cards when ANY of these conditions are met:
      // isTablet = windowSize < 1200px (desktTopStandard)
      // isPublic = public pages only render standard explorer cards (Modules only)
      // there are exactly 3 chapters
      return assets.map((assetProps, i) => {
        return (
          <ExplorerCard
            {...assetProps}
            ctaColor={ctaColor}
            windowSize={windowSize}
          />
        )
      })
    }
    if (!isTablet && assets.length < 3) {
      // if windowSize > 900px (tabletStandard) AND there are less than 3 chapters:
      // this will render 1 featured card, OR 2 vertically stacked featured cards
      return assets.map((assetProps, i) => {
        return (
          <ExplorerFeaturedCard
            {...assetProps}
            index={i}
            ctaColor={ctaColor}
            windowSize={windowSize}
          />
        )
      })
    }
    if (!isTablet && assets.length > 3) {
      // if windowSize > 900px (tabletStandard) AND there are more than 3 chapters:
      // render one large (featured) card followed by
      // small (standard) cards rendered horizontally a row(s) below it
      return assets.map((assetProps, i) => {
        return i === 0 ? (
          <ExplorerFeaturedCard
            {...assetProps}
            ctaColor={ctaColor}
            windowSize={windowSize}
          />
        ) : (
          <ExplorerCard
            {...assetProps}
            index={i}
            ctaColor={ctaColor}
            windowSize={windowSize}
          />
        )
      })
    }
  }

  return (
    <ExplorerContainer
      blockColor={blockColor}
      isTopicExplorer={true}
      cardCount={cardCount}
    >
      <div className="landing-header">
        <h1 className="heavy">{header}</h1>
        <p>{subheader}</p>
      </div>
      <div className="card-wrapper">{renderExplorerCards()}</div>
    </ExplorerContainer>
  )
}

Explorer.propTypes = {
  chapterUids: PropTypes.array,
  getDocument: PropTypes.func,
}

export const ExplorerContainer = styled.section`
  background: ${(props) =>
    props.isTopicExplorer ? explorerBackground : chapterExplorerBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3.125rem 1.875rem;

  .chapter-explorer-header {
    margin-bottom: 1.5rem;
    h1 {
      font-size: 1.5em;
      margin-bottom: 1rem;
    }
  }
  .landing-header {
    text-align: center;
    margin-bottom: 3.375rem;

    h1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-transform: uppercase;
      font-size: 2.25em;
      line-height: 1.15;
      margin-bottom: 2.125rem;

      &:after {
        content: "";
        height: 5px;
        width: 75px;
        background: ${(props) => props.blockColor};
        margin: 1rem auto 0;
      }
    }
    p {
      margin: 0;
    }
  }

  .card-wrapper {
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2.5rem;
  }

  // shared styles of child components
  .label-text {
    font-size: 0.75em;
    color: ${explorerCardLabel};
  }

  .button-link {
    font-family: "Futura", sans-serif;
    font-weight: 400;
  }

  ${mq.tabletTopicDetail`
    .card-wrapper {
      grid-template-columns: ${(props) =>
        props.cardCount === 1 ? "1fr" : "1fr 1fr"};
      grid-column-gap: 1.250rem;
    }
  `}

  ${mq.tabletStandard`
    .chapter-explorer-header {
      width: 100%;
      max-width: calc(1130px + 4rem);
    }
    .landing-header {
      h1 {
        margin-bottom: 1.875rem;
        font-size: 2.5rem;
      }
    }
    .card-wrapper {
      width: 100%;
      max-width: 1130px;
    }
  `}

  ${mq.desktopStandard`
    padding: 6rem 9rem 6.25rem 9rem;

    .chapter-explorer-header {
      max-width: 1130px;
      margin-bottom: 3rem;
    }
    .landing-header {
      margin-bottom: 4.125rem
    }
    .card-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `}
`

export default Explorer
