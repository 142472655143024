import React from "react"
import Helmet from "react-helmet"
import _get from "lodash/get"
import _pickBy from "lodash/pickBy"

const onlyTruthyValues = (obj) => _pickBy(obj, (item) => item)
const extractMetaDataFromProps = (props) => {
  const {
    partner: { name: partnerName, title: defaultTitle, canonicalUrl },
    chapter,
    topic,
  } = props

  const pageTitle = chapter ? chapter.title : topic.title
  const description = chapter ? chapter.description : topic.detailSubheader
  const image = determineImage(props)

  return {
    pageTitle,
    description,
    image,
    canonicalUrl: canonicalUrl || `https://${partnerName}.aperture.mfin.com/`,
    defaultTitle,
  }
}

const determineImage = (props) => {
  const { chapter, topic } = props
  if (!chapter) {
    return topic.heroImageMobile
  }

  return chapter.socialMediaThumbnail
    ? chapter.socialMediaThumbnail
    : chapter.cardImage
    ? chapter.cardImage
    : chapter.heroImageMobile
}
const Meta = (props) => {
  const sanitizedProps = {
    ...props,
    ...onlyTruthyValues(_get(props, "fields")),
    ...onlyTruthyValues(_get(props, "fields.meta")),
  }

  const {
    pageTitle,
    description,
    image,
    canonicalUrl,
    defaultTitle,
  } = extractMetaDataFromProps(sanitizedProps)

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={`${defaultTitle} | %s`}>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="noindex" />
      <link rel="canonical" href={canonicalUrl} />
      {/* og tags */}
      <meta property="og:image" content={image} itemProp="image" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      {/* twitter tags */}
      {/* <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} /> */}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default Meta
