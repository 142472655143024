import { isMobileDevice, isBrowserFireFox, isBrowserIE, isOldEdgeVersion } from "./device_utils"

const ConditionalWrapper = ({ fixBottom, wrapper, children }) => {
  return fixBottom ? wrapper(children) : children
}

const buildMailtoPayload = (url, subject) => {
  const encodedSubject = encodeURIComponent(subject.trim())
  return `mailTo:?subject=${encodedSubject}&body=${url}`
}

const buildModalVars = modalProps => {
  const {
    title,
    videoUrl,
    slug,
    pdfPath,
    pdfFileName,
    printableAssetPath,
    hideShareButton,
  } = modalProps

  const isVideoMedia = !!videoUrl
  const downloadableAssetPath = printableAssetPath || pdfPath
  const showDownloadButton = downloadableAssetPath && !isBrowserIE()
  const fileName = pdfFileName || slug
  const showPrintButton =
    !(isMobileDevice() && isBrowserFireFox()) && !!printableAssetPath
  const showInfoGraphicButtons = showDownloadButton || showPrintButton
  
  const showShareButton = !hideShareButton
  const showShareRow = showShareButton || showInfoGraphicButtons
  const isOldEdge = isOldEdgeVersion()

  return {
    title,
    isVideoMedia,
    downloadableAssetPath,
    showDownloadButton,
    fileName,
    showPrintButton,
    showInfoGraphicButtons,
    showShareButton,
    showShareRow,
    printableAssetPath,
    videoUrl,
    isOldEdge,
  }
}

export { buildModalVars, buildMailtoPayload, ConditionalWrapper }
