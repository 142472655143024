import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FixedBottom } from "react-fixed-bottom"
import NavigationRow from "../components/shared/navigation_row"
import NotFoundHeroBanner from "../components/not_found/not_found_hero_banner"
import NavigationMenu from "../components/navigation_menu/navigation_menu"
import Footer from "../components/footer/footer"
import TagManager from "react-gtm-module"
import { getGTM } from "../util/gtm_utils"

const NotFoundPage = ({
  partner,
  settings,
  getDocument,
  topics,
  isPublic,
  homeRoute,
  homeSlug,
}) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const toggleNavOpen = () => setNavOpen(!isNavOpen)

  const {
    blockColor,
    baseColor,
    footerLogo,
    footerImage,
    footerImageMobile,
    footerHeader,
    footerSubheader,
    privacyUrl,
    contactUsOptionalText,
  } = settings
  const {
    logo: logoUrl,
    logoLink,
    email,
    phoneNumber,
    footerText,
    title: partnerName,
    contactUsOverrideHtml,
  } = partner
  const footer = {
    footerLogo,
    footerImageMobile,
    footerImage,
    footerHeader,
    footerSubheader,
    privacyUrl,
    contactUsOptionalText,
    contactUsOverrideHtml
  }

  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const updateWindowSize = () => {
    setWindowSize(window.innerWidth)
  }

  // on component mount
  useEffect(() => {
    TagManager.dataLayer(getGTM())
    window.addEventListener("resize", updateWindowSize)
    return () => window.removeEventListener("resize", updateWindowSize)
  }, [])

  return (
    <NotFoundContainer>
      <NavigationMenu
        iconOnClick={toggleNavOpen}
        baseColor={baseColor}
        getDocument={getDocument}
        topics={topics}
        phoneNumber={phoneNumber}
        email={email}
        partnerLogo={logoUrl}
        isPublic={isPublic}
        isMenuOpen={isNavOpen}
        windowSize={windowSize}
        homeRoute={homeRoute}
        homeSlug={homeSlug}
      />
      <NavigationRow
        partnerLogo={logoUrl}
        partnerLogoLink={logoLink}
        iconOnClick={toggleNavOpen}
        baseColor={baseColor}
        isPublic={isPublic}
        phoneNumber={phoneNumber}
      />
      <FixedBottom offset={0}>
        <div className="body">
          <NotFoundHeroBanner blockColor={blockColor} homeRoute={homeRoute} />
          <Footer
            footer={footer}
            footerText={footerText}
            phoneNumber={phoneNumber}
            email={email}
            baseColor={baseColor}
            partnerName={partnerName}
            windowSize={windowSize}
            isPublic={isPublic}
          />
        </div>
      </FixedBottom>
    </NotFoundContainer>
  )
}

const NotFoundContainer = styled.div``

export default NotFoundPage
