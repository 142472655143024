import React from "react"
import styled from "styled-components"

const Icon = ({ name, color }) => {
  switch (name) {
    case "exit":
      return (
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 2L20.9047 20.9047" stroke={color} strokeWidth="4" />
          <path d="M20.9047 2L2 20.9047" stroke={color} strokeWidth="4" />
        </svg>
      )
    case "chevron":
      return (
        <svg
          width="24"
          height="16"
          viewBox="0 0 24 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.82 0L12 9.16L21.18 0L24 2.82L12 14.82L0 2.82L2.82 0Z"
            fill={color}
          />
        </svg>
      )
    case "menu":
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 2.5H25" stroke={color} strokeWidth="4" />
          <path d="M0 12.5H25" stroke={color} strokeWidth="4" />
          <path d="M0 22.5H25" stroke={color} strokeWidth="4" />
        </svg>
      )
    case "print":
      return (
        <SVGprint
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
        >
          <rect className="cls-1" x="16" y="1" width="32" height="19" />
          <polyline
            className="cls-1"
            points="16 52 1 52 1 20 63 20 63 52 48 52"
          />
          <rect className="cls-1" x="16" y="39" width="32" height="24" />
          <line className="cls-1" x1="22" y1="47" x2="42" y2="47" />
          <line className="cls-1" x1="22" y1="55" x2="42" y2="55" />
        </SVGprint>
      )
    case "download":
      return (
        <SVGdownload
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 63.71"
        >
          <polyline className="cls-1" points="40 50 32 58 24 50" />
          <line className="cls-2" x1="32" y1="58" x2="32" y2="26" />
          <polyline
            className="cls-2"
            points="24 42 1 42 1 6 63 6 63 42 40 42"
          />
        </SVGdownload>
      )
    case "sharePrint":
      return (
        <SharePrint
          width="45px"
          height="45px"
          viewBox="0 0 72 72"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Print" transform="translate(0.75 0.75)">
            <path
              d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
              className="cls-2"
              id="Oval-Copy-3"
            />
            <g id="Group-10" transform="translate(15 15)">
              <g id="Group-5">
                <path
                  d="M0 12.258L20.645 12.258L20.645 0L0 0L0 12.258Z"
                  transform="translate(9.677 0)"
                  className="cls-1"
                  id="Stroke-1"
                />
                <path
                  d="M9.678 20.646L0 20.646L0 0L40 0L40 20.646L30.323 20.646"
                  transform="translate(-0.0003 12.2573)"
                  className="cls-1"
                  id="Stroke-3"
                />
                <path
                  d="M0 15.483L20.645 15.483L20.645 0L0 0L0 15.483Z"
                  transform="translate(9.677 24.517)"
                  className="cls-1"
                  id="Stroke-4"
                />
              </g>
              <path
                d="M0 0.5L12.902 0.5"
                transform="translate(13.5488 29.1777)"
                className="cls-1"
                id="Stroke-6"
              />
              <path
                d="M0 0.5L12.902 0.5"
                transform="translate(13.5488 34.3389)"
                className="cls-1"
                id="Stroke-7"
              />
              <path
                d="M2.581 0.5L0 0.5"
                transform="translate(4.1836 16.3232)"
                className="cls-1"
                id="Stroke-8"
              />
              <path
                d="M2.581 0.5L0 0.5"
                transform="translate(8.3868 16.2744)"
                className="cls-1"
                id="Stroke-9"
              />
            </g>
          </g>
        </SharePrint>
      )
    case "shareDownload":
      return (
        <ShareDownload
          width="45px"
          height="45px"
          viewBox="0 0 72 72"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Download" transform="translate(0.75 0.75)">
            <path
              d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
              className="cls-2"
              id="Oval"
            />
            <path
              d="M9.03175 0L4.51587 4.51587L0 0"
              transform="translate(30.984253 47.838806)"
              className="cls-1"
              id="Stroke-1"
            />
            <path
              d="M0.4375 18.0644L0.4375 0"
              transform="translate(35.0625 34.290375)"
              className="cls-1"
              id="Stroke-3"
            />
            <path
              d="M12.9841 20.3228L0 20.3228L0 0L35 0L35 20.3228L22.0159 20.3228"
              transform="translate(17.999878 22.999634)"
              className="cls-1"
              id="Stroke-5"
            />
          </g>
        </ShareDownload>
      )
    case "shareShare":
      return (
        <ShareShare
          width="45px"
          height="45px"
          viewBox="0 0 72 72"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Share" transform="translate(0.75 0.75)">
            <path
              d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
              className="cls-2"
              id="Oval-Copy-4"
            />
            <g id="Group-11" transform="translate(17 17)">
              <path
                d="M0 7.00045C0 10.866 3.13402 14 6.99955 14C10.866 14 14 10.866 14 7.00045C14 3.13492 10.866 0 6.99955 0C3.13402 0 0 3.13492 0 7.00045Z"
                transform="translate(22 0)"
                className="cls-1"
                id="Stroke-1"
              />
              <path
                d="M12 5.99954C12 9.31318 9.31318 12 5.99954 12C2.68589 12 0 9.31318 0 5.99954C0 2.68589 2.68589 0 5.99954 0C9.31318 0 12 2.68589 12 5.99954Z"
                transform="translate(0 18)"
                className="cls-1"
                id="Stroke-3"
              />
              <path
                d="M0 5C0 2.23794 2.23988 0 5 0C7.76206 0 10 2.23794 10 5C10 7.76206 7.76206 10 5 10C2.23988 10 0 7.76206 0 5Z"
                transform="translate(22 26)"
                className="cls-1"
                id="Stroke-5"
              />
              <path
                d="M12 4L0 0"
                transform="translate(11 26)"
                className="cls-1"
                id="Stroke-7"
              />
              <path
                d="M0 8L12 0"
                transform="translate(11 12)"
                className="cls-1"
                id="Stroke-9"
              />
            </g>
          </g>
        </ShareShare>
      )
    default:
      return null
  }
}

export default Icon

const SharePrint = styled.svg`
  .cls-1 {
    stroke: #0d0e20;
    stroke-width: 1.5;
    fill: none;
    fill-rule: evenodd;
  }
  .cls-2 {
    fill: #eaeef1;
    fill-rule: evenodd;
    stroke: none;
  }
`
const ShareDownload = styled.svg`
  .cls-1 {
    stroke: #0d0e20;
    stroke-width: 1.5;
    fill: none;
    fill-rule: evenodd;
  }
  .cls-2 {
    fill: #eaeef1;
    fill-rule: evenodd;
    stroke: none;
  }
`
const ShareShare = styled.svg`
  .cls-1 {
    fill: none;
    fill-rule: evenodd;
    stroke: #0d0e20;
    stroke-width: 1.5;
  }
  .cls-2 {
    fill: #eaeef1;
    fill-rule: evenodd;
    stroke: none;
  }
`

const SVGprint = styled.svg`
  .cls-1 {
    fill: none;
    stroke: #0058a4;
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }
`
const SVGdownload = styled.svg`
  .cls-1,
  .cls-2 {
    fill: none;
    stroke: #0058a4;
    stroke-width: 4px;
  }
  .cls-1 {
    stroke-linejoin: bevel;
  }
  .cls-2 {
    stroke-miterlimit: 10;
  }
`
