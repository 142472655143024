import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { mq } from "../../assets/shared_styles"
import { useHistory, useLocation } from "react-router"
import { gtmId, gtmTypes } from "../../util/gtm_utils"

const { navigationChapterLabels } = appColors
const { navMenuChapterLink } = gtmTypes

const NavigationChapterItem = ({ chapter, closeNav, isAdvancedTopic }) => {
  const { title: chapterTitle, number, route, slug, uid, whiteList } = chapter
  const history = useHistory()
  const location = useLocation()

  // todo: for board demo
  const isBlocked = whiteList !== null && whiteList !== uid

  const isAdvanced = (isAdvanced) => {
    const textOutput = isAdvanced ? "" : "Chapter " + number
    return <p className="chapter-number">{textOutput}</p>
  }
  const handleChapterLink = () => {
    const linkPath = route
    const currentRoute = location.pathname

    if (isBlocked) {
      alert("Coming Soon")
    } else {
      if (linkPath !== currentRoute) {
        history.push(linkPath + window.location.search)
      } else {
        closeNav()
      }
    }
  }
  return (
    <NavigationChapterItemContainer
      className="chapter-item link pointer no-child-pointer-events"
      id={gtmId(navMenuChapterLink, slug)}
      onClick={handleChapterLink}
    >
      <div>
        {isAdvanced(isAdvancedTopic)}
        <a href={route + window.location.search} className="title emphatic-text" data-apt-page={route + window.location.search}>
          {chapterTitle}
        </a>
      </div>
    </NavigationChapterItemContainer>
  )
}

NavigationChapterItem.propTypes = {
  chapter: PropTypes.object,
}

const NavigationChapterItemContainer = styled.div`
  margin-bottom: 1.875rem;

  .chapter-number {
    color: ${navigationChapterLabels};
    margin-bottom: 0.5rem;
    font-size: 1.125em;
  }

  .title {
    font-size: 1.15em;
    color: white;
    text-decoration: none;
    margin: 0;
  }

  ${mq.newerPhoneStandard`
    .chapter-number {
      margin-bottom: 0.5rem;
    }
  `}

  ${mq.tabletNavigationMenu`
    padding-right: 1rem;

    .chapter-number {
      font-size: 1em;
    }
  `}
  ${mq.tabletStandard`
    /*margin-bottom: 2.25rem;*/
    margin-bottom: 1.25rem;
  `}
`

export default NavigationChapterItem
