import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { appStrings } from "../../assets/app_strings"
import { mq } from "../../assets/shared_styles"
import Icon from "../shared/icon"
import NavigationChapterItem from "./navigation_chapter_item"
// import { useHistory, useLocation } from "react-router"
import { gtmId, gtmTypes } from "../../util/gtm_utils"

const { navigationDivider, whiteText } = appColors
const { viewTopic } = appStrings.navigationMenu
const { navMenuTopicLink, navMenuExpandedTopicLink } = gtmTypes

const NavigationTopicItem = ({ topic, getDocument, closeNav }) => {
  const [topicIsOpen, toggleTopicOpen] = useState(false)

  const {
    title: topicTitle,
    chapters: chapterUids,
    slug,
    isAdvancedTopic,
  } = topic
  const chapterCount = chapterUids ? chapterUids.length : 0
    // const history = useHistory()
    // const location = useLocation()

    // const handleTopicLink = () => {
    //   const linkPath = topic.route
    //   const currentRoute = location.pathname
    //   if (linkPath !== currentRoute) {
    //     return linkPath + window.location.search
    //   } else {
    //     closeNav()
    //   }
    // }

  return (
    <NavigationTopicItemContainer
      topicIsOpen={topicIsOpen}
      chapterCount={chapterCount}
    >
      <div
        className="title-container pointer"
        onClick={() => toggleTopicOpen(!topicIsOpen)}
      >
        <a
          href={topic.route + window.location.search}
          data-apt-page={topic}
          className="heavy"
          id={gtmId(navMenuTopicLink, slug)}
        >
          {topicTitle}
        </a>
        <div className="icon-container">
          <span>{topicIsOpen ? "Collapse" : "Expand"}</span>
          <Icon name="chevron" color={whiteText} />
        </div>
      </div>
      <div className="chapter-list">
        <div>
          {(chapterUids || []).map((chapterObj) => {
            const { chapter: uid } = chapterObj
            const chapter = getDocument("chapters", "uid", uid)

            return (
              <NavigationChapterItem
                chapter={chapter}
                topicTitle={topicTitle}
                key={uid}
                closeNav={closeNav}
                isAdvancedTopic={isAdvancedTopic}
              />
            )
          })}
          <div className="link overview">
            <a
              href={topic.route + window.location.search}
              data-apt-page={navMenuExpandedTopicLink}
              className="pointer"
              id={gtmId(navMenuExpandedTopicLink, slug)}
            >
              {viewTopic(topicTitle)}
            </a>
          </div>
        </div>
      </div>
    </NavigationTopicItemContainer>
  )
}

NavigationTopicItem.propTypes = {
  topic: PropTypes.object,
  getDocument: PropTypes.func,
}

const NavigationTopicItemContainer = styled.div`
  color: ${whiteText};
  a {
    color: ${whiteText};
    text-decoration: none;
  }
  .link:hover {
    h3,
    a {
      opacity: 0.5;
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    //margin-bottom: 1.25rem;
    //padding-bottom: 1.125rem;
    margin: 1rem 0;
    padding: 0;
    /*border-bottom: 1px solid ${navigationDivider};*/

    h3,
    a {
      font-size: 1.25em;
      text-transform: uppercase;
      /*margin: 0.5rem 0 0 0;*/
      max-width: 80%;
      margin: 0;
    }
  }

  svg {
    transition: all 0.25s;
    transform: rotateZ(${(props) => (props.topicIsOpen ? 180 : 0)}deg);
    user-select: none;
  }

  .chapter-list {
    height: 100%;
    // this is an impossibly high number on purpose
    max-height: ${(props) => (props.topicIsOpen ? 5000 : 0)}px;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    border-bottom: 1px solid ${navigationDivider};
    /*margin-bottom: ${(props) => (props.topicIsOpen ? 1.5 : 0)}rem;*/
    margin-bottom: 1rem;

    > div {
      //display: flex;
      //flex-direction: column;
      //flex-wrap: wrap;
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);
      padding: 0 20px;
    }
  }

  .overview {
    /*margin-bottom: 2.25rem;*/
    margin-bottom: 1.25rem;

    h3,
    a {
      margin: 0;
    }
  }

  .icon-container {
    position: relative;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: space-evenly;

    > span {
      display: none;
    }
  }

  ${mq.newerPhoneStandard`
    .icon-container {
      /*align-items: flex-end;*/

      > span {
        font-size: 0.75em;
        margin: 0 1rem 0 0;
      }
      svg {
        margin-bottom: 4px;
      }
    }
  `}

  ${mq.tabletNavigationMenu`
    .title-container {
      /*margin-bottom: 2.5rem;*/

      &:hover {
        opacity: 0.5;
      }
    }

    .chapter-list {
      .chapter-item, .overview {
        /*width: ${(props) => 100 / Math.ceil(props.chapterCount / 2) - 1}%;*/
      }
      > div {
        /*max-height: 282px;*/
      }
    }

    .overview {
      margin-bottom: 1.25rem;
    }
    .icon-container {
      > span {
        display: inline;
      }
    }
  `}
`

export default NavigationTopicItem
