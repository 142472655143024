import React from "react"
import "./setupTests.js"
import ReactDOM from "react-dom"

import "modern-normalize/modern-normalize.css"
import "./globalStyles.css"
import App from "./App"
import registerServiceWorker, { unregister } from "./registerServiceWorker"
import data from "./data.json"
import { waitForStyleSheetsLoaded } from "./util/utils.js"

const rootEl = document.getElementById("root")

// only render when style sheets are fully loaded
waitForStyleSheetsLoaded(document).then(() => {
  ReactDOM.render(<App />, rootEl)
  if (module.hot) {
    module.hot.accept("./App", () => {
      const NextApp = require("./App").default
      ReactDOM.render(<NextApp />, rootEl)
    })
  }
})

if (process.env.REACT_APP_SITE_URL && "localStorage" in window) {
  window.localStorage.setItem("netlifySiteURL", process.env.REACT_APP_SITE_URL)
}

const globalSettings =
  data.settings && data.settings.filter(doc => doc.name === "global")[0]

if (globalSettings) {
  globalSettings.enableServiceWorker ? registerServiceWorker() : unregister()
}
