import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { mq } from "../../assets/shared_styles"

const { darkText } = appColors

const TopicDetail = ({ detailHeader, detailSubheader, icon, blockColor }) => {
  return (
    <TopicDetailContainer blockColor={blockColor}>
      <div className="header">
        <h2 className="heavy">{detailHeader}</h2>
        <p>{detailSubheader}</p>
      </div>
      <img src={icon} alt={"Topic Icon"} />
    </TopicDetailContainer>
  )
}

TopicDetail.propTypes = {
  detailHeader: PropTypes.string,
  detailSubheader: PropTypes.string,
  icon: PropTypes.string,
  blockColor: PropTypes.string,
}

const TopicDetailContainer = styled.section`
  max-width: 1328px;
  width: 100%;
  color: ${darkText};
  padding: 10.625rem 2.5rem 1.125rem;
  justify-content: space-between;

  img {
    display: none;
  }

  .header {
    h2 {
      text-transform: uppercase;
      font-size: 2.25em;
      line-height: 1.2;

      &:after {
        margin: 1rem 0 2.25rem;
        content: "";
        display: block;
        height: 5px;
        width: 75px;
        background: ${props => props.blockColor};
      }
    }
    p {
      margin: 0;
    }
  }

  // normal phone+
  ${mq.newerPhoneStandard`
    padding: 8.75rem 2.25rem 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      font-size: 2.250em;
    }
  `}

  ${mq.tabletStandard`
    padding: 10.07rem 6.250rem 3.75rem 9.375rem;
    .header {
      max-width: 100%;
      h2 {
        &:after {
          margin: 1rem 0 1.875rem;
        }
      }
    }
  `}

    ${mq.desktopStandard`
    .header {
      max-width: 620px;
    }
    img {
      display: block;
      width: 300px;
      height: 300px;
    }
  `}
`

export default TopicDetail
