import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { sharedStyles } from "../../assets/shared_styles"
import { useHistory } from "react-router"
import {
  getButtonText,
  calculateExplorerCardDimensions,
} from "../../util/component_utils"
import { appStrings } from "../../assets/app_strings"
import { gtmTypes, gtmId } from "../../util/gtm_utils"
import { getImageSrc } from "../../util/getImageUrl"

const { buttonStyler } = sharedStyles
const {
  explorerFeaturedCardBorder,
  explorerCardBackground,
  defaultButtonAccent,
  explorerCardLabel,
} = appColors

const { cardLabel } = appStrings.explorer
const { explorerFeaturedCard } = gtmTypes

const ExplorerFeaturedCard = ({
  index,
  asset,
  isModuleCard,
  handleShowMediaModal,
  themeColor,
  ctaColor,
  windowSize,
  isAdvancedTopic,
}) => {
  const {
    cardImage: chapterCardImage,
    number,
    title,
    description,
    image: modCardImage,
    route,
    slug,
    cardImageAltText: altText,
    uid,
    whiteList,
  } = asset
  const chapterLabel = cardLabel(number, isAdvancedTopic)
  const cardImage = isModuleCard ? modCardImage : chapterCardImage
  const buttonText = getButtonText(asset, isModuleCard, isAdvancedTopic)
  const history = useHistory()
  const gtmType = explorerFeaturedCard
  const cardImageSize = calculateExplorerCardDimensions(windowSize)

  // todo: for board demo
  const isBlocked = whiteList !== null && whiteList !== uid

  const handleCardClick = () => {
    if (isModuleCard) {
      window.history.pushState(null, "", route + window.location.search)
      handleShowMediaModal(asset)
    } else {
      if (isBlocked) {
        alert("Coming Soon")
      } else {
        history.push(route + window.location.search)
      }
    }
  }

  return (
    <ExplorerFeaturedCardContainer
      className="featured-card pointer no-child-pointer-events"
      cardImage={getImageSrc(cardImage, cardImageSize)}
      heroImage={cardImage}
      index={index}
      themeColor={themeColor}
      ctaColor={ctaColor}
      onClick={handleCardClick}
      id={gtmId(gtmType, slug)}
    >
      <div className="card-image" role="img" aria-label={altText} />
      <div className="card-info">
        <div className="header-wrapper">
          {!isModuleCard && <p className="label-text">{chapterLabel}</p>}
          <h3 className="demi">{title}</h3>
        </div>
        <p className="description">{description}</p>
        <div className="button-link">{buttonText}</div>
      </div>
    </ExplorerFeaturedCardContainer>
  )
}

ExplorerFeaturedCard.propTypes = {
  index: PropTypes.number,
  chapter: PropTypes.object,
  ctaColor: PropTypes.string,
}

const ExplorerFeaturedCardContainer = styled.button`

  // Remove button styling
  text-align: inherit;
  padding: inherit;
  border: none;

  width: 100%;
  max-width: 1130px;
  height: 400px;
  display: flex;
  flex-direction: ${props => (props.index % 2 === 0 ? "row-reverse" : "row")};
  grid-area: auto / span 3;
  border-${props =>
    props.index % 2 === 0
      ? "left"
      : "right"}: 5px solid ${explorerFeaturedCardBorder};
    
  .card {
    &-image,
    &-info {
      width: 50%;
      max-width: 565px;
      height: 100%;
    }
    &-image {
      background-image: url(${props => props.cardImage});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
    }
    &-info {
      background: ${explorerCardBackground};
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .label-text {
        color: ${explorerCardLabel};
        font-size: 0.75em;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h3 {
        font-size: 1.75em;
      }
      .description {
        font-size: 1em;
      }
      .button-link {
        ${buttonStyler(explorerCardBackground, defaultButtonAccent, false)}
      }
    }
  }
`

export default ExplorerFeaturedCard
