import React, { useState, useCallback, useEffect } from "react"
import "malihu-custom-scrollbar-plugin"
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css"
import $ from "jquery"
import styled from "styled-components"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import TagManager from "react-gtm-module"
import { FixedBottom } from "react-fixed-bottom"
import { mq } from "../../assets/shared_styles"
import { appColors } from "../../assets/app_colors"
import { breakpoints } from "../../assets/app_consts"
import { gtmTypes, getGTM } from "../../util/gtm_utils"
import {
  buildModalVars,
  ConditionalWrapper,
} from "../../util/modal_utils"
import loadingPng from "../../assets/static_images/FFFFFF-0.png"
import { getImageSrc } from "../../util/getImageUrl"
import { ShareRow } from '../shared/share_row'

require("jquery-mousewheel")
const {
  mediaModalBackground,
  darkText,
  whiteText,
  scrollBarHandle,
  scrollTrack,
} = appColors
const { tabletTopicDetail } = breakpoints
const {
  modalShareButton,
} = gtmTypes

const media = (module, videoLoaded, setVideoLoaded, isMobile) => {
  const { mediaImage, videoUrl, infographicAltText, title } = module
  const sizedMediaImage = isMobile
    ? getImageSrc(mediaImage, 600)
    : getImageSrc(mediaImage, 1200)

  const altText = infographicAltText
    ? infographicAltText
    : `${title} info-graphic`

  return videoUrl ? (
    <div
      className="player-wrapper"
      alt="module video"
      style={{ display: videoLoaded ? "block" : "none" }}
    >
      <ReactPlayer
        url={videoUrl}
        className="react-player"
        controls={true}
        width="100%"
        height="100%"
        onReady={() => {
          setVideoLoaded(true)
        }}
      />
    </div>
  ) : (
      <img src={sizedMediaImage} alt={altText} />
    )
}

const loadingVideo = module => {
  const { videoUrl } = module

  return (
    <div className="player-wrapper" alt="module video">
      <ReactPlayer
        url={videoUrl}
        className="react-player"
        controls
        playing
        width="100%"
        height="100%"
        light={loadingPng}
        playIcon={<div></div>}
      />
    </div>
  )
}

const MediaModal = ({
  module,
  handleToggleModal,
  hideShareButton,
  disablePrintAndDownload,
}) => {
  const [windowHeight, setWindowSize] = useState(window.innerHeight)
  const [videoLoaded, setVideoLoaded] = useState(false)

  const modalProps = { ...module, hideShareButton }
  const {
    title,
    isVideoMedia,
    downloadableAssetPath,
    fileName,
    showInfoGraphicButtons,
    showShareRow,
    printableAssetPath,
    videoUrl,
    isOldEdge,
  } = buildModalVars(modalProps)

  const windowWidth = window.innerWidth
  const isMobile = windowWidth < tabletTopicDetail

  const escapeModal = useCallback(
    event => {
      if (event.keyCode === 27) {
        handleToggleModal(false)
      }
    },
    [handleToggleModal],
  )

  const updateWindowHeight = () => {
    setWindowSize(window.innerHeight)
  }

  // on component mount
  useEffect(() => {
    document.addEventListener("keydown", escapeModal, false)
    return () => {
      document.removeEventListener("keydown", escapeModal, false)
    }
  }, [escapeModal])

  useEffect(() => {
    TagManager.dataLayer(getGTM())
    window.addEventListener("resize", updateWindowHeight)
    window.addEventListener("orientationchange", updateWindowHeight)
    return () => {
      window.removeEventListener("resize", updateWindowHeight)
      window.removeEventListener("orientationchange", updateWindowHeight)
    }
  }, [])

  useEffect(() => {
    if (!videoUrl) {
      $(".media-window").mCustomScrollbar({
        theme: "rounded",
        scrollButtons: { enable: false },
      })
    }
  }, [videoUrl])

  return (
    <ConditionalWrapper
      fixBottom={!videoUrl}
      wrapper={children => <FixedBottom offset={20}>{children}</FixedBottom>}
    >
      <ModalContainer
        className={videoUrl ? "video-media" : "infographic-media"}
        windowHeight={windowHeight}
        isVideoMedia={isVideoMedia}
        hideInfoGraphicButtons={!showInfoGraphicButtons}
        hideShareButton={hideShareButton}
        showShareRow={showShareRow}
        isOldEdge={isOldEdge}
      >
        <div className="button-row">
          <div className="icon-container" onClick={handleToggleModal}>
            <span role='button'>View More</span>
          </div>
        </div>
        <div className="media-window">
          {!videoLoaded && videoUrl && loadingVideo(module)}
          {media(module, videoLoaded, setVideoLoaded, isMobile)}
        </div>
        {showShareRow && (
          <div className="share-row">
            <ConditionalWrapper
              fixBottom={isMobile}
              wrapper={children => (
                <FixedBottom offset={0}>{children}</FixedBottom>
              )}
            >
              <ShareRow
                title={title}
                isVideoMedia={!!videoUrl}
                downloadableAssetPath={downloadableAssetPath}
                fileName={fileName}
                modalShareButton={modalShareButton}
                printableAssetPath={printableAssetPath}
                disablePrintAndDownload={disablePrintAndDownload}
                justifyAlignment={'center'}
              />
            </ConditionalWrapper>
          </div>
        )}
      </ModalContainer>
    </ConditionalWrapper>
  )
}

MediaModal.propTypes = {
  module: PropTypes.object,
}

const ModalContainer = styled.div`
  width: calc(100% - 20px);
  height: calc(${props => props.windowHeight}px - 65px);
  display: flex;
  flex-direction: column;
  margin: 100px auto 0;
  background: ${mediaModalBackground};
  color: ${darkText};
  z-index: 1000;

  &.video-media {
    max-height: ${props => (props.showShareRow ? "480px;" : "410px;")};
    max-height: fit-content;
    ${props =>
    !props.isOldEdge
      ? `max-width: calc(${props.windowHeight}px - 40px);`
      : ""}
    margin-top: 15vh;
    height: fit-content;
  }
  > div {
    z-index: 1001;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -28px;
    min-height: 10px;
    height: 10px;
    .icon-container {
      cursor: pointer;
      color: ${whiteText};
    }
  }

  .media-window {
    margin: 0 0 0.5em 0;
    padding: 0 0.625em;
    overflow-y: auto;
     /* height: calc(100% - 58px);  leave in for now in case need for revert */
    height: 100%;
    img {
      width: 100%;
    }
    /* makes video player responsive.  code taken from react-player docs */
    .player-wrapper {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player {
      position: absolute;
      max-height: inherit;
      top: 0;
      left: 0;
    }
    .mCSB {
      &_draggerRail {
        background-color: ${scrollTrack};
      }
      &_dragger_bar {
        background-color: ${scrollBarHandle};
      }
    }
  }

  .share-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    margin: 0 0 0.5em 0;
  }

  ${mq.tabletTopicDetail`
    margin: 80px auto 0;
    position: relative;

    .media-window {
      margin-bottom: 2.250em;
      margin-bottom: ${props => (props.showShareRow ? "2.25em;" : ".5em;")}
    }

    &.video-media {
      max-height: ${props => (props.showShareRow ? "550px;" : "430px;")}
      max-height: fit-content;
      height: fit-content;
      ${props => (props.isOldEdge ? "width: 750px;" : "")}

      .media-window {
        margin-bottom: ${props => (props.hideShareButton ? ".5em" : "2.25em")};
      }
    }

    .share-row {
      margin-bottom: 2.250em;
    }
  `}

  ${mq.desktopStandard`
    height: calc(${props => props.windowHeight}px - 75px);
    width: 70%;

    .media-window {
      margin-bottom: 2.250em;
    }

    &.video-media {
      max-height: ${props => (props.showShareRow ? "790px;" : "710px;")}
      max-height: fit-content;
      height: fit-content;
      ${props => (props.isOldEdge ? "width: 1200px;" : "")}

      .media-window {
        margin-bottom: 2.250em;
        ${props => (props.isOldEdge ? "height: unset;" : "")}
      }
    }

    .button-row {
      height: 2.5rem;
    }

    .media-window {
      padding: 0 2.5rem;
    }
  `}
`

export default MediaModal
