import {
  isMobile,
  isFirefox,
  isChrome,
  isAndroid,
  isIOS as isIos,
  osName,
  browserName,
  isSafari,
  isMobileSafari,
  browserVersion,
  isIE,
  isEdge
} from "react-device-detect"

// Browser and Device Checks
// if more checks are needed, see React-Device-Detect docs:
// https://www.npmjs.com/package/react-device-detect

// return true if device is phone OR tablet
const isMobileDevice = () => {
  return isMobile
}

const isBrowserFireFox = () => {
  return isFirefox
}

const isBrowserChrome = () => {
  return isChrome
}

const isAndroidOS = () => {
  return isAndroid
}

const isIOS = () => {
  return isIos
}

const isBrowserSafari = () => {
  return isSafari
}

const isBrowserSafariMobile = () => {
  return isMobileSafari
}

const getOSName = () => {
  return osName
}

const getBrowserName = () => {
  return browserName
}

const getBrowserVersion = () => {
  return browserVersion
}

const isBrowserIE = () => {
  return isIE
}

const isOldEdgeVersion =() => {
  const isOld = browserVersion === "17" || browserVersion === "18"
  return isEdge && isOld
}

// Log React-Detect-Device values to console
const browserReport = () => {
  const detectedValues = [
    isMobile,
    isFirefox,
    isChrome,
    isAndroid,
    isIos,
    osName,
    browserName,
    isSafari,
    isMobileSafari,
    browserVersion,
    isIE,
  ]

  const funcNamesString =
    "isMobile, isFirefox, isChrome, isAndroid, isIOS, osName, browserName, isSafari, isMobileSafari, browserVersion, isIE"
  const funcNames = funcNamesString.split(",")
  let i = 0
  detectedValues.forEach(value => {
    console.log(funcNames[i], value)
    i++
  })
}

export {
  isMobileDevice,
  isBrowserFireFox,
  isBrowserChrome,
  isAndroidOS,
  isIOS,
  getOSName,
  getBrowserName,
  browserReport,
  isBrowserSafari,
  isBrowserSafariMobile,
  getBrowserVersion,
  isBrowserIE,
  isOldEdgeVersion,
}
