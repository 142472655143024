const breakpoints = {
  newerPhoneStandard: 376,
  tabletNavigationMenu: 630,
  tabletChapterSelector: 675,
  tabletTopicDetail: 750,
  tabletExplorer: 812,
  tabletStandard: 900,
  desktopStandard: 1200,
  desktopExplorer: 1328,
}

const lineCharacterLimits = {
  explorerCardHeader: 25,
  chapterSelectorCardHeader: 15,
  topicHeroHeader: 20,
}

const visibleSelectorCards = {
  desktop: 3.85,
}

export { breakpoints, lineCharacterLimits, visibleSelectorCards }
