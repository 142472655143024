import React from "react"
import styled from "styled-components"
import { appColors } from "../../assets/app_colors"
import { appStrings } from "../../assets/app_strings"
import { attachSanitizedAttributes, sanitizeHTML} from "../../util/component_utils"
import ContactUsEmails from "./contact_us_emails"

const { call, orEmail, noPhoneNumber } = appStrings.heroFooter
const { callToday } = appStrings.navigationMenu
const { whiteText } = appColors

const ContactUs = ({
  phoneNumber,
  email,
  isFooter,
  isNavbar,
  contactUsOptionalText,
}) => {

  // set listener that will apply attributes to sanitized <a/> tags
  attachSanitizedAttributes()

  const cleancontactUsOptionalText = sanitizeHTML(contactUsOptionalText)

  if (isNavbar) {
    if (!phoneNumber) {
      return (
        <Contact className="contact" isFooter={isFooter}>
          {noPhoneNumber}
          <ContactUsEmails email={email} />
        </Contact>
      )
    } else {
      return (
        <Contact className="contact" isFooter={isFooter}>
          {callToday}
          <span className="phone-number">{phoneNumber}</span>
        </Contact>
      )
    }
  }

  if (!phoneNumber) {
    return (
        <Contact className="contact" isFooter={isFooter}>
        {contactUsOptionalText ? (
          <p
            className="optional-text"
            dangerouslySetInnerHTML={{ __html: cleancontactUsOptionalText }}
          />
        ) : (
          <>
            {noPhoneNumber}
            <ContactUsEmails email={email} />
          </>
        )}
      </Contact>
    )
  } else {
    return (
      <Contact className="contact" isFooter={isFooter}>
            {call}
        <span className="phone-number">{phoneNumber}</span>
        {contactUsOptionalText ? (
          <p
            className="optional-text"
            dangerouslySetInnerHTML={{ __html: cleancontactUsOptionalText }}
          />
        ) : (
          <>
            {orEmail}
            {!isFooter}
            <ContactUsEmails email={email} />
          </>
        )}
      </Contact>
    )
  }
}

const Contact = styled.div`
  font-size: 22px;
  text-align: ${props => (props.isFooter ? "left" : "center")};
  font-family: ${props => (props.isFooter ? "futura-pt" : "proxima-nova")},
    sans-serif;
  font-weight: 400;

  a,
  .phone-number {
    color: ${whiteText};
    /*font-weight: ${props => (props.isFooter ? "700" : "600")};*/
    text-decoration: none;
  }

  .optional-text {
    margin: 0;
  }
`

export default ContactUs
