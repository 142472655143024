const colors = {
  white: "#FFFFFF",
  mBlack: "#0D0E20",
  mYellow: "#FFCB05",
  mCyan: "#00B1FF",
  mGrey: "#EBEEF1",
  iron: "#D7DADE",
  manatee: "#9B9BA2",
  osloGray: "#858790",
  mOrange: "#FFAA40",
  pumpkin: "#FF700D",
  greenish: "#3FAD7D",
  mFinDarkBlue: "#17365E",
  mFinBlue: "#0058A4",
  mFinGreen: "#47A63D",
  mFinRed: "#BF2026",
  jumbo: "#86868f",
  athensGray: "#F3F5F7",
  smokeWhite: "#f5f5f5",
  halfOpaqueWhite: "rgba(255, 255, 255, 0.5)",
  fifthOpaqueWhite: "rgba(255, 255, 255, 0.2)",
  cadetBlue: "#A2AFC0"
}

const appColors = {
  whiteText: colors.white,
  darkText: colors.mBlack,
  neutralChapterSelectorCard: colors.mGrey,
  neutralChapterSelectorCardBorder: colors.iron,
  neutralChapterSelectorChapterNumber: colors.osloGray,
  hoverChapterSelectorCardBorder: colors.fifthOpaqueWhite,
  titleAccentUnderline: colors.mYellow,
  explorerBackground: colors.smokeWhite,
  explorerFeaturedCardBorder: colors.iron,
  explorerCardBackground: colors.white,
  explorerCardLabel: colors.manatee,
  chapterExplorerCardBackground: colors.smokeWhite,
  chapterExplorerBackground: colors.white,
  defaultButtonAccent: colors.mFinBlue,
  navigationDivider: colors.jumbo,
  navigationChapterLabels: colors.jumbo,
  mediaModalBackground: colors.smokeWhite,
  shareMenuBackground: colors.athensGray,
  shareMenuItemBorder: colors.iron,
  shareMenuItemHover: colors.iron,
  siteFooterDivider: colors.halfOpaqueWhite,
  siteFooterHostData: colors.halfOpaqueWhite,
  scrollBarHandle: colors.cadetBlue,
  scrollTrack: colors.osloGray,
}

export { appColors }
