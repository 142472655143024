import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { mq } from "../../assets/shared_styles"
import { appColors } from "../../assets/app_colors"
import { appStrings } from "../../assets/app_strings"
import { gtmId, gtmTypes } from "../../util/gtm_utils"
import {
  attachSanitizedAttributes,
  sanitizeHTML,
} from "../../util/component_utils"

const { whiteText, siteFooterDivider, siteFooterHostData } = appColors
const { mFinCopyright } = appStrings.siteFooter
const { footerPrivacyLink: gtmType } = gtmTypes

const SiteFooter = ({
  baseColor,
  logo,
  partnershipText,
  privacyUrl,
  partnerName,
  isPublic,
  altText,
}) => {
  // set listener that will apply attributes to sanitized <a/> tags
  attachSanitizedAttributes()

  const showPartnerName = !isPublic
  const cleanPartnershipText = sanitizeHTML(partnershipText)

  return (
    <SiteFooterContainer
      baseColor={baseColor}
      footerText={cleanPartnershipText}
    >
      <div className="image-wrapper">
        <img src={logo} alt={altText} />
      </div>
      <div className="host-data">
        <div className="footer-copy">
          <p dangerouslySetInnerHTML={{ __html: cleanPartnershipText }}></p>
        </div>
      </div>
      <div className="copyright">
        <a
          href={privacyUrl}
          id={gtmId(gtmType, privacyUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="book">Privacy Policy</p>
        </a>
        <p className="book">{mFinCopyright(partnerName, showPartnerName)}</p>
      </div>
    </SiteFooterContainer>
  )
}

SiteFooter.propTypes = {
  baseColor: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  partnershipText: PropTypes.string.isRequired,
}

const SiteFooterContainer = styled.div`
  background: ${(props) => props.baseColor};
  padding: 2.188rem 1.875rem 2.188rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr minmax(130px, 25%);
  grid-template-columns: 1fr minmax(130px, 25%);
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;

  > div {
    color: ${whiteText};
    &.image-wrapper {
      grid-area: 1 / 1;
      -ms-grid-row: 1;
      -ms-grid-column: 1;

      display: flex;
      align-items: center;
      img {
        width: 100%;
        max-width: 150px;
      }
    }
    &.host-data {
      margin-top: 2em;
      padding-top: 2em;
      border-top: 1px solid ${siteFooterDivider};
      color: ${siteFooterHostData};
      -ms-grid-row: 2;
      grid-area: 2 / span 2;

      .footer-copy {
        p {
          margin: 0;
          font-size: 0.75em;
          line-height: 1.71;

          a {
            color: ${siteFooterHostData};
          }
        }
      }
    }
    &.copyright {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      grid-area: 1 / 2;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      a {
        color: white;
        text-decoration: none;
      }

      a:hover {
        opacity: 0.5;
      }

      p {
        font-size: 0.875em;
        line-height: 1.7;
        margin: 0;
      }
    }
  }

  ${mq.newerPhoneStandard`
    > div {
      &.copyright {
        p {
          font-size: 0.9em;
          }
        }
      }
  `}

  ${(props) =>
    props.footerText.length > 1000
      ? mq.tabletStandard`
    padding: 1.188rem 1.875rem 2.188rem;
    > div {
      &.copyright {
        p {
          font-size: 0.9em;
          }
        }
        &.host-data {
          margin-top: 1em;
          padding-top: 1em;
        }
      }

  `
      : mq.tabletStandard`
    grid-template-columns: 175px auto 300px;

    > div {
      max-height: 76px;
      &.image-wrapper {
        max-height: inherit;

        img {
          max-height: inherit;
          max-width: 175px;
        }
      }

      &.host-data {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-area: 1 / 2;
        margin: 0 0 0 2rem;
        padding: 0 0 0 2rem;
        border-top: none;
        border-left: 1px solid ${siteFooterDivider};
        max-width: 90%;
        height: 76px;
        display: flex;
        align-items: center;
      }
      &.copyright {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        grid-area: 1 / 3;
        p {
          margin: 0;
        }
      }
    }
  `}
`

export default SiteFooter
