import React, { Component, Fragment } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import TagManager from "react-gtm-module"
import Meta from "./components/Meta"
import styled, { createGlobalStyle } from "styled-components"
import TopicLandingPage from "./pages/topic_landing_page"
import ChapterLandingPage from "./pages/chapter_landing_page"
import NotFoundPage from "./pages/not_found"
import MediaModal from "./components/media_modal/media_modal"
import ServiceWorkerNotifications from "./components/ServiceWorkerNotifications"
import data from "./data.json"
import {
  bootstrapSiteData,
  setTabContent,
  overrideSettings,
  overrideTopic,
  getDefaultTopicUid,
} from "./util/component_utils"
import { mq } from "./assets/shared_styles"
import { appColors } from "./assets/app_colors"
import { DevAdvancedTopic } from "./components/DevAdvancedTopic"

// overrides
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const queryPartner = urlParams.get("partner")
const queryPublic = urlParams.get("public")
const queryWhiteList = urlParams.get("whiteList")
const queryShowDisabled = urlParams.get("showDisabled")

const {
  REACT_APP_CURRENT_TENANT,
  REACT_APP_GTM: GTM,
  REACT_APP_IS_PUBLIC,
  REACT_APP_WHITE_LIST,
  REACT_APP_SHOW_DISABLED,
} = process.env

const CURRENT_TENANT = queryPartner || REACT_APP_CURRENT_TENANT
const WHITE_LIST = queryWhiteList || REACT_APP_WHITE_LIST
const IS_PUBLIC = queryPublic || REACT_APP_IS_PUBLIC
const isPublic = IS_PUBLIC === "true"
const whiteList = WHITE_LIST || null
const SHOW_DISABLED = queryShowDisabled || REACT_APP_SHOW_DISABLED
const showDisabled = SHOW_DISABLED === "true"

const tagManagerArgs = {
  gtmId: GTM,
}

TagManager.initialize(tagManagerArgs)

const currentPartner =
  data.partners.find((partner) => partner.name === CURRENT_TENANT) ||
  "m-financial"
const { routeMetaData, dataWithRoutes } = bootstrapSiteData(
  data,
  isPublic,
  currentPartner,
  ChapterLandingPage,
  TopicLandingPage,
  whiteList,
  showDisabled,
)

const { defaultButtonAccent } = appColors

const RouteWithMeta = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={(routeProps) => (
      <Fragment>
        <Meta {...props} />
        <Component {...routeProps} {...props} />
      </Fragment>
    )}
  />
)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: dataWithRoutes,
      isModalOpen: false,
      selectedModule: {},
    }
  }

  componentDidMount() {
    setTabContent(currentPartner)

    window.onpopstate = () => {
      if (this.state.isModalOpen) {
        this.handleToggleModal()
      }
    }
  }

  setModal = (value) => {
    this.setState({ isModalOpen: value })
  }

  setSelectedModule = (module) => {
    this.setState({ selectedModule: module })
  }

  handleToggleModal = () => {
    const {
      isModalOpen,
      selectedModule: { parentRoute },
    } = this.state
    this.setModal(!isModalOpen)
    if (isModalOpen) {
      window.history.pushState(null, "", parentRoute + window.location.search)
    }
  }

  handleShowMediaModal = (module) => {
    this.setSelectedModule(module)
    this.handleToggleModal()
  }

  handleOverlayClick = (e) => {
    if (e.target.id === "overlay") {
      this.handleToggleModal()
    }
  }

  getDocument = (collection, key, value) => {
    return (
      this.state.data[collection] &&
      this.state.data[collection].filter((page) => page[key] === value)[0]
    )
  }
  getDocuments = (collection) => this.state.data[collection] || []

  getPublicModules = (topic) => {
    const { uid: topicUid } = topic
    const { chapters } = this.getDocument("topics", "uid", topicUid)

    return chapters.reduce((acc, { chapter: c }) => {
      const chapter = this.getDocument("chapters", "uid", c)
      const modArray = chapter.modules.filter((mod) => mod.isPublic)
      return [...acc, ...modArray]
    }, [])
  }

  render() {
    if (window.location.pathname === "/developer/") {
      return (
        <AppContainer>
          <GlobalStyle />
          <DevAdvancedTopic />
        </AppContainer>
      )
    }

    const globalSettingsRaw =
      this.getDocument("settings", "name", "global") || ""
    const globalSettings = overrideSettings(globalSettingsRaw, currentPartner)
    const defaultTopicTitle = getDefaultTopicUid(globalSettings, currentPartner)
    const defaultTopicRaw =
      this.getDocument("topics", "uid", defaultTopicTitle) || ""
    const defaultTopic = overrideTopic(
      defaultTopicRaw,
      currentPartner,
      this.state.data,
      showDisabled,
    )
    const { route: homeRoute, slug: homeSlug } = defaultTopic
    const defaultPublicModules = isPublic
      ? this.getPublicModules(defaultTopic)
      : []
    const topics = this.state.data.topics
    const { isModalOpen, selectedModule } = this.state
    const { hideShareButton, disablePrintAndDownload } = currentPartner

    return (
      <AppContainer>
        {isModalOpen && (
          <div
            id="overlay"
            className="overlay"
            onClick={(e) => this.handleOverlayClick(e)}
          >
            <GlobalStyle />
            <MediaModal
              module={selectedModule}
              handleToggleModal={this.handleToggleModal}
              hideShareButton={hideShareButton}
              disablePrintAndDownload={disablePrintAndDownload}
            />
          </div>
        )}
        <Router>
          <ServiceWorkerNotifications reloadOnUpdate />
          <Switch>
            <RouteWithMeta
              path="/"
              exact
              component={TopicLandingPage}
              partner={currentPartner}
              topic={defaultTopic}
              settings={globalSettings}
              getDocument={this.getDocument}
              topics={topics}
              isPublic={isPublic}
              handleShowMediaModal={this.handleShowMediaModal}
              publicModules={defaultPublicModules}
              homeRoute={homeRoute}
              homeSlug={homeSlug}
            />
            {!!routeMetaData.length &&
              routeMetaData.map((route) => {
                const topicProps = route.topic
                  ? {
                      publicModules: isPublic
                        ? this.getPublicModules(route.topic)
                        : [],
                    }
                  : {}

                return (
                  <RouteWithMeta
                    {...route}
                    {...topicProps}
                    getDocument={this.getDocument}
                    settings={globalSettings}
                    handleShowMediaModal={this.handleShowMediaModal}
                    topics={topics}
                    isPublic={isPublic}
                    homeRoute={homeRoute}
                    homeSlug={homeSlug}
                    exact
                  />
                )
              })}
            <RouteWithMeta
              component={NotFoundPage}
              partner={currentPartner}
              settings={globalSettings}
              getDocument={this.getDocument}
              topics={topics}
              isPublic={isPublic}
              homeRoute={homeRoute}
              homeSlug={homeSlug}
            />
          </Switch>
        </Router>
      </AppContainer>
    )
  }
}

export default App

// .swal classes are for styling the SweetAlert module  print dialogue
// injected as global styles here because of the the way the dialogues are attached to the DOM
const GlobalStyle = createGlobalStyle`
  .swal-overlay--show-modal, .swal-modal {
    animation: none !important;
  }

  .swal-overlay {
    background-color: rgba(0, 0, 0, 0.7)
  }

  .swal-modal {
    border: 2px solid ${defaultButtonAccent};
    border-radius: 4px;

    .swal-text {
      color: black;
      font-family: "proxima-nova", sans-serif;
      letter-spacing: 0.01em;
      font-weight: 400;
      line-height: 1.4;
    }

    .swal-button {
      border-radius: 4px;
      font-family: "proxima-nova", sans-serif;
      letter-spacing: 0.01em;
      font-weight: 400;

      &:focus {
        box-shadow: none;
      }
    }

    .swal-button--confirm {
      background: white;
      color: ${defaultButtonAccent};
      border: 2px solid ${defaultButtonAccent};

      &:hover {
        background-color: inherit;
      }
    }
  }
`

const AppContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  position: absolute;

  .no-child-pointer-events * {
    pointer-events: none;
  }

  .overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.94);
  }

  // todo: for board demo
  li:focus {
    outline: none !important;
  }
  ${mq.tabletStandard`
  .body {
    top: 90px;
  }
  `}
`
