import React from "react"
import styled from "styled-components"

const ContactUsEmails = ({ email }) => {
  const getMailTo = (email) => {
    return `mailto: ${email}`
  }
  if (email !== undefined) {
    const emails = email.split(/[\s ,]/).filter((email) => email)
    const emailsLength = emails.length
    return (
      <>
        {emails.map((email) => {
          const mailto = getMailTo(email)
          return (
            <EmailSpan emails={emailsLength} key={email}>
              <a href={mailto}>{email}</a>
            </EmailSpan>
          )
        })}
      </>
    )
  } else {
    return null
  }
}

const EmailSpan = styled.span`
  ${({ emails }) =>
    emails > 1
      ? `
      &:nth-last-child(${emails})::before {
        content: "";
      }
      &::before {
        content: ", ";
      }
      &:last-child::before {
    content: " or ";
  }`
      : ``}
`

export default ContactUsEmails
