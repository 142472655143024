import * as detect from "./device_utils"
import swal from "sweetalert"
import { appStrings } from "../assets/app_strings"

const { iosAlert, defaultAlert, printFailure } = appStrings.mediaModal

// Print Methods

// Print using hidden Iframe
const printIframe = ref => {
  const printFrame = ref.current.contentWindow
  printFrame.focus()
  printFrame.print()
}

// Print by opening a new window with injected scripts for window.print()
const printNewWindow = printableAssetPath => {
  const url = `${window.location.origin}${printableAssetPath}`
  const myWindow = window.open(url, "_blank", "fullscreen=yes")
  const theScript = document.createElement("script")
  theScript.innerHTML = `window.onload = () => { window.setTimeout(function() {window.print(); }, 500) }`
  myWindow.document.body.appendChild(theScript)
}

// open a dialogue that will ask user for permission to open
// a new window where they can use the device's/pdf viewers native print buttons
const printDialogue = printableAssetPath => {
  const confirmMessage = detect.isIOS() ? iosAlert : defaultAlert
  swal(confirmMessage, { buttons: ["Nevermind", "Ok ›"] }).then(value => {
    if (value) {
      printNewWindow(printableAssetPath)
      swal.close()
    } else {
      return
    }
  })
}

const printFailureDialogue = () => {
  const confirmMessage = printFailure
  swal(confirmMessage)
}

// return the correct print action to MediaModal
const getPrintAction = ({ isMobile, isMobileSafari, isFireFox }) => {
  // device is mobile OR tablet
  if (isMobile) {
    // browser is NOT MobileSafari
    if (!isMobileSafari) {
      return "printDialogue"
    } else {
      // browser is MobileSafari
      return "printNewWindow"
    }
  }
  // device is not mobile AND browser is Firefox
  if (isFireFox) {
    return "printDialogue"
  }
  // default desktop behavior
  if (!isMobile) {
    return "printIframe"
  }
  return
}

// SweetAlert is a customizable dialogue library
// you can develop dialogue behavior here before setting it handlePrintAsset
// https://sweetalert.js.org/docs/
const testSweetAlert = () => {
  swal("This is a sweetAlert test dialoge", {
    buttons: ["Nevermind", "Ok ›"],
  }).then(value => {
    alert(`Alert value: ${value}`)
  })
}

export {
  testSweetAlert,
  getPrintAction,
  printIframe,
  printDialogue,
  printNewWindow,
  printFailureDialogue,
}
