import { appStrings } from "../assets/app_strings"
import { breakpoints, visibleSelectorCards } from "../assets/app_consts"
import dompurify from "dompurify"

const { playVideo, showMore, viewChapter } = appStrings.explorer
const { tabletStandard, tabletTopicDetail } = breakpoints
const { desktop } = visibleSelectorCards

const calculateBoxShadowColor = (color, opacity) => {
  if (color.startsWith("#")) {
    return `${color}${opacity}`
  }
  if (color.startsWith("rgb(")) {
    var match = color.match(
      /rgb?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/,
    )
    return `rgba(${match[0]}, ${match[1]}, ${match[2]}, 0.${opacity})`
  }
  return color
}

const getButtonText = (asset, isModuleCard, isAdvancedTopic) => {
  if (!isModuleCard) {
    return viewChapter(isAdvancedTopic)
  }
  if (asset.videoUrl) {
    return playVideo
  } else {
    return showMore
  }
}

const buildAssetProps = ({
  chapterUids,
  getDocument,
  themeColor,
  publicModules,
  handleShowMediaModal,
  isPublic,
  topicTitle,
  isAdvancedTopic,
}) => {
  if (!isPublic) {
    return (chapterUids || []).reduce((acc, { chapter: uid }) => {
      const chapter = getDocument("chapters", "uid", uid)
      const assetProps = {
        key: uid,
        asset: chapter,
        isModuleCard: false,
        topicTitle,
        themeColor,
        chapterTitle: chapter.title,
        isPublic,
        isAdvancedTopic,
      }
      return [...acc, assetProps]
    }, [])
  }
  return publicModules.map((module) => {
    const { uid } = module
    return {
      key: uid,
      asset: module,
      isModuleCard: true,
      topicTitle,
      themeColor,
      chapterTitle: "",
      isPublic,
      handleShowMediaModal,
      isAdvancedTopic,
    }
  })
}

const parseContentOverrides = (contentOverrides) => {
  if (!contentOverrides) {
    return null
  }

  const overridePairs = contentOverrides.split(",")
  let defaultTopic = ""
  const topics = overridePairs.reduce((acc, pair) => {
    const [topic, chapter] = pair.split(":")
    if (!defaultTopic) {
      defaultTopic = topic
    }
    const record = acc[topic] ? acc[topic] : { chapters: [] }
    const chapters = !!chapter ? [...record.chapters, chapter] : record.chapters
    return {
      ...acc,
      [topic]: {
        chapters,
      },
    }
  }, {})

  return {
    defaultTopic,
    topics,
  }
}

// returns object { routes, stateData }
// todo: remove whiteList from function before production deployment
const bootstrapSiteData = (
  data,
  isPublic,
  currentPartner,
  chapterComponent,
  topicComponent,
  whiteList,
  showDisabled,
) => {
  console.log(window.location.pathname)
  if (window.location.pathname === "/developer/") {
    return {}
  }
  let routeMetaData = []
  const dataWithRoutes = data.topics.reduce(
    (acc, topicRaw) => {
      const contentOverrides = parseContentOverrides(
        currentPartner.contentOverrides,
      )
      if (
        contentOverrides &&
        Object.keys(contentOverrides.topics).indexOf(topicRaw.uid) < 0
      ) {
        return acc
      }
      const topic = overrideTopic(topicRaw, currentPartner, data, showDisabled)
      const { slug, chapters, isAdvancedTopic = false } = topic
      const isAdvanced = JSON.parse(isAdvancedTopic) || false
      const tRoute = `/${slug}`
      const topicRoute = {
        key: tRoute,
        path: tRoute,
        component: topicComponent,
        partner: currentPartner,
        topic: { ...topic, route: tRoute, isAdvancedTopic: isAdvanced },
      }
      routeMetaData.push(topicRoute)
      let topicChapters = []
      if (chapters !== undefined) {
        topicChapters = chapters.map(({ chapter: id }) => {
          const chapterRaw = data.chapters.find((chapter) => chapter.uid === id)
          const chapter = overrideChapter(chapterRaw, currentPartner)
          const chapPath = `/${chapter.slug}`

          const chapterPath = tRoute + chapPath
          const chapterRouteProps = {
            key: chapterPath,
            path: chapterPath,
            component: chapterComponent,
            partner: currentPartner,
            topic: topic,
            chapter: { ...chapter, route: chapterPath },
            selectedModuleFromUrl: null,
          }

          const newModules = chapter.modules.map((mod) => {
            const r = `/${mod.slug}`
            const route = isPublic ? tRoute + r : chapterPath + r
            const parentRoute = isPublic ? tRoute : chapterPath
            const baseProps = isPublic ? topicRoute : chapterRouteProps
            const moduleRouteProps = {
              ...baseProps,
              key: route,
              path: route,
              parentRoute,
              selectedModuleFromUrl: { ...mod, route, parentRoute },
            }
            if (!isPublic || mod.isPublic) {
              routeMetaData.push(moduleRouteProps)
            }
            return {
              ...mod,
              route,
              parentRoute,
            }
          })
          const newChapter = {
            ...chapter,
            whiteList,
            route: chapterPath,
            modules: newModules,
          }

          if (!isPublic) {
            routeMetaData.push({ ...chapterRouteProps, chapter: newChapter })
          }
          return newChapter
        })
      }
      return {
        ...acc,
        chapters: [...acc.chapters, ...topicChapters],
        topics: [...acc.topics, { ...topic, route: tRoute }],
      }
    },
    { ...data, topics: [], chapters: [] },
  )

  return {
    dataWithRoutes,
    routeMetaData,
  }
}

const calculateExplorerCardDimensions = (windowWidth) => {
  if (windowWidth > tabletStandard) {
    return 364
  }
  if (windowWidth <= tabletTopicDetail) {
    return 364
  }
  if (windowWidth <= tabletStandard && windowWidth > tabletTopicDetail) {
    return 800
  }
  return 800
}

const calculateIfFontShouldBeSmaller = (
  text,
  maxLineLength,
  maxNumberOfLines,
) => {
  const stringPool = text.split(/\s+/)
  let currentLine = []
  const finalSentences = []

  while (stringPool.length > 0) {
    const word = stringPool.shift()

    if (word) {
      // if word exists, push into current line
      currentLine.push(word)
      const tooLong = currentLine.join(" ").length > maxLineLength
      // if currentLine is too long, put the last word back in the stringPool,
      // push the currentLine into finalSentences,
      // then reset currentLine to be an empty array
      if (tooLong) {
        currentLine.pop()
        stringPool.unshift(word)

        finalSentences.push(currentLine.join(" "))
        currentLine = []
      }
    }
  }
  // handle remainder words by pushing them into the finalSentences anyway
  if (currentLine.length > 0) {
    finalSentences.push(currentLine.join(" "))
  }
  return finalSentences.length > maxNumberOfLines
}

const setFavicon = (img) => {
  var favicon = document.querySelector('link[rel="shortcut icon"]')

  if (!favicon) {
    favicon = document.createElement("link")
    favicon.setAttribute("rel", "shortcut icon")
    var head = document.querySelector("head")
    head.appendChild(favicon)
  }

  favicon.setAttribute("href", img)
}

const setTabContent = (partner) => {
  const { title: tabText, favicon } = partner
  document.title = tabText
  if (favicon) {
    setFavicon(favicon)
  }
}

const calculateVisibleSelectorCards = (windowWidth) => {
  const isWindows = window.navigator.platform.includes("Win")
  const macScrollbar = 15
  const windowsScrollbar = 17
  const cardWidth = 226

  const scrollbarWidth = isWindows ? windowsScrollbar : macScrollbar
  const offset = isWindows
    ? windowsScrollbar / cardWidth
    : macScrollbar / cardWidth

  if (windowWidth >= tabletStandard + scrollbarWidth) {
    return desktop
  } else {
    return 0.00425 * windowWidth + (0.0304 - offset)
  }
}

const generateUidsToRender = (uids, visibleCards) =>
  Math.ceil(visibleCards) <= uids.length ? [...uids, ...uids] : uids

const sanitizeHref = (href) => {
  return href.split("?")[0]
}

const overrideSettings = (globalSettings, currentPartner) => {
  const { footerImage, footerImageMobile, footerImageAltText } = globalSettings
  const {
    footerImageOverride,
    footerImageMobileOverride,
    footerImageAltTextOverride,
  } = currentPartner

  return {
    ...globalSettings,
    footerImage: footerImageOverride || footerImage,
    footerImageMobile: footerImageMobileOverride || footerImageMobile,
    footerImageAltText: footerImageAltTextOverride || footerImageAltText,
  }
}

const overrideTopic = (topicToOverride, currentPartner, data, showDisabled) => {
  const contentOverrides = parseContentOverrides(
    currentPartner.contentOverrides,
  )
  const topic = pruneChapters(
    topicToOverride,
    data,
    showDisabled,
    contentOverrides,
  )
  const { uid: topicUid, heroImage, heroImageMobile, topicAltText } = topic
  const { topicOverrides } = currentPartner

  if (topicOverrides) {
    const overrides = topicOverrides.find((el) => el.topicUid === topicUid)

    if (overrides) {
      const { imageOverride, imageMobileOverride, altTextOverride } = overrides

      return {
        ...topic,
        heroImage: imageOverride || heroImage,
        heroImageMobile: imageMobileOverride || heroImageMobile,
        topicAltText: altTextOverride || topicAltText,
      }
    } else {
      return topic
    }
  } else {
    return topic
  }
}

const pruneChapters = (topic, data, showDisabled, contentOverrides) => {
  if (showDisabled && !contentOverrides) {
    return topic
  }

  const returnPruned = () => {
    if (topic.chapters) {
      const prunedChapters = topic.chapters.reduce((acc, c) => {
        const { chapter: CID } = c
        const chapter = data.chapters.find((chapter) => chapter.uid === CID)
        return chapter.isEnabled ? [...acc, c] : acc
      }, [])
      return { ...topic, chapters: prunedChapters }
    } else {
      return {
        ...topic,
      }
    }
  }

  if (!!contentOverrides) {
    const topicOverride = contentOverrides.topics[topic.uid]
    if (!topicOverride) {
      // no chapters, if no topic override exists
      return { ...topic, chapters: [] }
    }

    // topic override exists
    if (topicOverride.chapters.length < 1) {
      // no specified chapters means just include all the chapters
      return returnPruned()
    } else {
      const { chapters } = data
      const positions = topicOverride.chapters.reduce(
        (acc, next, idx) => ({
          ...acc,
          [next]: idx,
        }),
        {},
      )
      const gathered = chapters
        .filter((c) => topicOverride.chapters.indexOf(c.uid) > -1)
        .sort((a, b) =>
          positions[a.uid] > positions[b.uid]
            ? 1
            : positions[a.uid] < positions[b.uid]
            ? -1
            : 0,
        )
        .map((c) => ({ chapter: c.uid }))
      return { ...topic, chapters: gathered }
    }
  }

  return topic === "" ? "" : returnPruned()
}

const overrideChapter = (chapter, currentPartner) => {
  const { uid: chapterUid, cardImage, cardImageAltText } = chapter
  const { chapterOverrides } = currentPartner

  if (chapterOverrides) {
    const overrides = chapterOverrides.find(
      (el) => el.chapterUid === chapterUid,
    )

    if (overrides) {
      const { imageOverride, altTextOverride } = overrides

      return {
        ...chapter,
        cardImage: imageOverride || cardImage,
        cardImageAltText: altTextOverride || cardImageAltText,
      }
    } else {
      return chapter
    }
  } else {
    return chapter
  }
}

// Dompurify functions to sanitize and set dangerouslySetHTML text links
const attachSanitizedAttributes = () => {
  dompurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank")
      node.setAttribute("rel", "noopener")
    }
    // set non-HTML/MathML links to xlink:show=new
    if (
      !node.hasAttribute("target") &&
      (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
    ) {
      node.setAttribute("xlink:show", "new")
    }
  })
}

const sanitizeHTML = (text) => {
  return dompurify.sanitize(text)
}

const getDefaultTopicUid = (globalSettings, currentPartner) => {
  const contentOverrides = parseContentOverrides(
    currentPartner.contentOverrides,
  )
  if (contentOverrides) {
    return contentOverrides.defaultTopic
  }

  return globalSettings.defaultTopic
}

export {
  calculateBoxShadowColor,
  getButtonText,
  buildAssetProps,
  bootstrapSiteData,
  calculateExplorerCardDimensions,
  calculateIfFontShouldBeSmaller,
  setTabContent,
  calculateVisibleSelectorCards,
  generateUidsToRender,
  sanitizeHref,
  overrideSettings,
  overrideTopic,
  overrideChapter,
  attachSanitizedAttributes,
  sanitizeHTML,
  getDefaultTopicUid,
}
