import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { breakpoints } from "../../assets/app_consts"
import { getImageSrc } from "../../util/getImageUrl"
import ChapterSelector from "../chapter_selector/chapter_selector"
import { mq, sharedStyles } from "../../assets/shared_styles"
import { appColors } from "../../assets/app_colors"
import { calculateIfFontShouldBeSmaller } from "../../util/component_utils"
import { lineCharacterLimits } from "../../assets/app_consts"

const { whiteText } = appColors
const { scalingFontSize } = sharedStyles

const { tabletNavigationMenu } = breakpoints

const HeroText = ({ title, description }) => {
  return (
    <div className="hero-text">
      <h1 className="heavy">{title}</h1>
      <p className="medium">{description}</p>
    </div>
  )
}
const LandingHeroBanner = ({
  title,
  description,
  heroImageUrl,
  heroImageMobileUrl,
  chapterUids,
  getDocument,
  themeColor,
  windowSize,
  isPublic,
  altText,
  isAdvancedTopic,
}) => {
  const useSmallerFont = calculateIfFontShouldBeSmaller(
    title,
    lineCharacterLimits.topicHeroHeader,
    2,
  )

  const isMobile = windowSize < tabletNavigationMenu
  const mobileImageUrl = heroImageMobileUrl
    ? getImageSrc(heroImageMobileUrl, 800)
    : getImageSrc(heroImageUrl, 800)
  const imageUrl = isMobile ? mobileImageUrl : getImageSrc(heroImageUrl, 1800)
  let displayCarousel = false
  if (chapterUids !== undefined) {
    displayCarousel =
      chapterUids.length > 1 &&
      !isPublic &&
      (isMobile ? chapterUids.length > 2 : true)
  }

  return (
    <LandingHeroBannerContainer
      heroImageUrl={imageUrl}
      useSmallerFont={useSmallerFont}
    >
      <div role="img" aria-label={altText} />
      <HeroText title={title} description={description} />
      {displayCarousel && (
        <ChapterSelector
          topicTitle={title}
          chapterUids={chapterUids}
          getDocument={getDocument}
          themeColor={themeColor}
          windowSize={windowSize}
          isAdvancedTopic={isAdvancedTopic}
        />
      )}
    </LandingHeroBannerContainer>
  )
}

LandingHeroBanner.propTypes = {
  partnerLogo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  heroImageUrl: PropTypes.string,
  toggleNavOpen: PropTypes.func,
  chapterUids: PropTypes.array,
  isAdvancedTopic: PropTypes.bool,
}

const LandingHeroBannerContainer = styled.section`
  min-height: 540px;
  display: flex;
  background-image: url(${(props) => props.heroImageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 8rem 2.25rem 0;

  .hero-text {
    width: 100%;
    color: ${whiteText};

    h1 {
      ${scalingFontSize(40, 64)}
      text-transform: uppercase;
      margin-bottom: 0.25em;
      line-height: 1;
    }
    p {
    }
  }

  ${mq.newerPhoneStandard`
    padding: 0 2.25rem;
    align-items: center;
  `}

  ${mq.tabletTopicDetail`
    min-height: 665px;
    padding: 0 0 0 9.375rem;

    .hero-text {
      p {
        font-size: 1em;
        max-width: 80%;
      }
    }
  `}

  ${mq.tabletStandard`
    .hero-text {
      max-width: 90%;
      h1 {
        ${(props) =>
          props.useSmallerFont
            ? "font-size: 3.875em;"
            : scalingFontSize(64, 96)}
      }
      p {
        max-width: 45%;
        padding-left: 0.5rem;
      }
    }
  `}

  ${mq.desktopStandard`
    .hero-text {
      h1 {
        ${scalingFontSize(64, 96)}
        max-width: 1440px;
      }
    }
  `}
`

export default LandingHeroBanner
