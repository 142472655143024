import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { mq, sharedStyles } from "../../assets/shared_styles"
import { appColors } from "../../assets/app_colors"

const { darkText, whiteText, titleAccentUnderline } = appColors
const { scalingFontSize } = sharedStyles

const ChapterHeroText = ({
  title,
  chapterNumber,
  isAdvancedTopic,
  subtitle,
}) => {
  const subtitleToUse = isAdvancedTopic ? subtitle : "Chapter " + chapterNumber

  return (
    <div className="hero-text">
      <h2 className="medium">{subtitleToUse}</h2>
      <h1 className="heavy">{title}</h1>
    </div>
  )
}
const determineHeroImage = (
  isAdvancedTopic,
  heroImage,
  advHeroImage,
  advHeroImageMobile,
) => {
  if (isAdvancedTopic) {
    return advHeroImageMobile ? advHeroImageMobile : advHeroImage
  }
  return heroImage
}

const ChapterHeroBanner = ({
  title,
  subtitle = "",
  number,
  themeColor,
  heroImage,
  isAdvancedTopic,
  advHeroImage,
  advHeroImageMobile,
}) => {
  return (
    <ChapterHeroBannerContainer
      blockColor={whiteText}
      accentSubtitle={titleAccentUnderline}
      themeColor={themeColor}
      imageUrl={heroImage}
      isAdvancedTopic={isAdvancedTopic}
      darkText={darkText}
      advHeroImage={advHeroImage}
      advHeroImageMobile={advHeroImageMobile}
    >
      <ChapterHeroText
        title={title}
        chapterNumber={number}
        isAdvancedTopic={isAdvancedTopic}
        subtitle={subtitle}
      />
    </ChapterHeroBannerContainer>
  )
}

ChapterHeroBanner.propTypes = {
  partnerLogo: PropTypes.string,
  title: PropTypes.string,
  chapters: PropTypes.array,
}

export const ChapterHeroBannerContainer = styled.div(
  (props) => css`
    background-color: ${props.themeColor};
    background-image: url("${determineHeroImage(
      props.isAdvancedTopic,
      props.imageUrl,
      props.advHeroImage,
      props.advHeroImageMobile,
    )}");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right 20px bottom -36px;
    background-attachment: scroll;
    color: ${whiteText};
    min-height: ${props.isAdvancedTopic ? "400px" : "250px"};
    width: 100%;
    padding: 1.5em 2.25em;
    /* If isAdvancedTopic apply this block */
    ${props.isAdvancedTopic &&
    css`
      display: flex;
      align-items: center;
      background-color: ${props.blockColor};
      background-position: right 0px bottom 0px;
      background-repeat: no-repeat;
      background-size: cover;
      color: ${whiteText};
      ::before {
        content: "";
        background-color: ${props.themeColor};
        height: 10px;
        top: 0%;
        left: 0%;
        width: 100%;
        position: absolute;
      }
    `}

    .hero-text {
      max-width: ${props.isAdvancedTopic ? "500px" : "600px"};

      h1 {
        ${scalingFontSize(24, 60)};
        line-height: 1;
        text-transform: uppercase;

        ${props.isAdvancedTopic &&
        css`
          text-transform: capitalize;
        `}
      }
      h2 {
        font-size: 1.3em;
        margin-bottom: 1em;
        ${props.isAdvancedTopic &&
        css`
          margin-bottom: 0.5em;
          padding-top: 0.5em;
          text-transform: uppercase;
        `}
        &:after {
          content: "";
          margin-top: 0.4em;
          display: block;
          height: 5px;
          width: 85px;
          background: ${props.isAdvancedTopic
            ? props.accentSubtitle
            : props.blockColor};
        }
      }
    }

    ${mq.newerPhoneStandard`
      padding: 2.250em 1.25em 4.93em 3em;
      background-size: ${props.isAdvancedTopic ? "cover" : "40%"};
    `}
    ${mq.tabletStandard`
      padding: 3.125rem 1.875rem;
      background-size: ${props.isAdvancedTopic ? "cover" : "300px"};
      background-position: ${
        props.isAdvancedTopic ? "right 0% top 50%" : "right 20px top 50%"
      };
      .hero-text {
        h1 {
          font-size: 3em;
       }
      }
    `}
    ${mq.desktopStandard`
      background-image: ${
        props.isAdvancedTopic ? `url("${props.advHeroImage}")` : "inherit"
      };
      background-position:  ${
        props.isAdvancedTopic ? "right 0% top 50%" : "right 144px top 50%"
      };
      background-size: ${props.isAdvancedTopic ? "cover" : "inherit"};
      padding: 2.250em 0 4.93rem 9rem;
    `}
  `,
)

export default ChapterHeroBanner
