import React, { useState, useRef } from "react"
import email from "../../assets/static_images/email_icon.png"
import copy from "../../assets/static_images/copy_icon.png"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { buildMailtoPayload } from "../../util/modal_utils"
import { appStrings } from "../../assets/app_strings"
import { gtmTypes, gtmId } from "../../util/gtm_utils"
import { sanitizeHref } from "../../util/component_utils"
import { mq } from "../../assets/shared_styles"
import { appColors } from "../../assets/app_colors"
import styled, { css } from "styled-components"
import Icon from "../shared/icon"
import {
  isMobileDevice,
  isBrowserFireFox,
  isBrowserSafariMobile,
  isBrowserIE,
} from "../../util/device_utils"
import {
  getPrintAction,
  printIframe,
  printNewWindow,
  printDialogue,
  printFailureDialogue,
} from "../../util/print_utils.js"
import { breakpoints } from "../../assets/app_consts"

const {
  shareMenuCopyLink,
  shareMenuEmailLink,
  shareMenuCloseMenu,
  modalDownloadPdfButton,
  modalPrintPdfButton,
} = gtmTypes

const { tabletTopicDetail } = breakpoints
const { share, copyLink, emailLink, linkCopied } = appStrings.mediaModal

const {
  darkText,
  shareMenuBackground,
  shareMenuItemBorder,
  shareMenuItemHover,
} = appColors

export const ShareRow = ({
  title = "",
  isVideoMedia = false,
  downloadableAssetPath = "",
  fileName = "",
  modalShareButton = "",
  printableAssetPath = "",
  disablePrintAndDownload = false,
  justifyAlignment = "center",
  position, // 'top' | 'bottom' | undefined
}) => {
  const [shareOptionsOpen, toggleShareOptions] = useState(false)
  const renderIframe = !isBrowserFireFox() && !isMobileDevice()
  const windowWidth = window.innerWidth
  const isMobile = windowWidth < tabletTopicDetail

  const printPdf = () => {
    const isMobile = isMobileDevice()
    const isFireFox = isBrowserFireFox()
    const isMobileSafari = isBrowserSafariMobile()
    const printAction = getPrintAction({ isMobile, isFireFox, isMobileSafari })

    if (printAction === "printIframe") {
      printIframe(iframeRef)
    }
    if (printAction === "printNewWindow") {
      printNewWindow(printableAssetPath)
    }
    if (printAction === "printDialogue") {
      printDialogue(printableAssetPath)
    }
    if (!printAction) {
      printFailureDialogue()
    }
  }

  const [isCopied, setIsCopied] = useState(false)
  const url = sanitizeHref(window.location.href)
  const hrefPayload = buildMailtoPayload(url, title)

  const handleCopyUrl = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  const handleCloseShareMenu = () => {
    toggleShareOptions(false)
  }

  const iframeRef = useRef()
  const showPrintAndDownload =
    !!downloadableAssetPath && !disablePrintAndDownload

  return (
    <ShareRowContainer
      isVideoMedia={isVideoMedia}
      modalShareButton={modalShareButton}
      showPrintAndDownload={showPrintAndDownload}
      justifyAlignment={justifyAlignment}
      position={position}
    >
      <IconContainer showPrintAndDownload={showPrintAndDownload}>
        {showPrintAndDownload && !isBrowserIE() && (
          <>
            <a href={downloadableAssetPath} download={fileName}>
              <div
                className="svg-container no-child-pointer-events"
                id={gtmId(modalDownloadPdfButton, "pdf-download", module.slug)}
              >
                <Icon className="print-img" name="shareDownload"></Icon>
                Download
              </div>
            </a>
            <div
              className="svg-container no-child-pointer-events"
              onClick={() => printPdf()}
              id={gtmId(modalPrintPdfButton, "print-pdf", module.slug)}
            >
              <Icon className="print-img" name="sharePrint"></Icon>
              Print
            </div>
          </>
        )}
      </IconContainer>
      <ShareButtonContainer
        isVideoMedia={isVideoMedia}
        showPrintAndDownload={showPrintAndDownload}
      >
        <div
          className="svg-container"
          onClick={() => toggleShareOptions(!shareOptionsOpen)}
          id={gtmId(modalShareButton, "toggle-share-menu", module.slug)}
        >
          <Icon className="print-img" name="shareShare"></Icon>
          Share
        </div>
        <ShareMenuContainer
          shareOptionsOpen={shareOptionsOpen}
          isMobile={isMobile}
          isVideoMedia={isVideoMedia}
          modalShareButton={modalShareButton}
        >
          <div
            className="menu-item bolder-text no-child-pointer-events"
            onClick={() => handleCloseShareMenu()}
            id={gtmId(shareMenuCloseMenu, "close-share-menu")}
          >
            {share}
            <Icon name="exit" color={darkText} />
          </div>
          <CopyToClipboard onCopy={handleCopyUrl} text={url}>
            <div
              className="menu-item no-child-pointer-events"
              id={gtmId(shareMenuCopyLink, "copy-link")}
            >
              {isCopied ? linkCopied : copyLink}
              <img src={copy} alt="Copy icon" />
            </div>
          </CopyToClipboard>
          <a className="no-decoration" href={hrefPayload}>
            <div
              className="menu-item no-child-pointer-events"
              id={gtmId(shareMenuEmailLink, "email-link")}
            >
              {emailLink}
              <img src={email} alt="Email icon" />
            </div>
          </a>
        </ShareMenuContainer>
      </ShareButtonContainer>
      {renderIframe && (
        <iframe
          className="print-frame"
          title="pdfToPrint"
          src={printableAssetPath}
          ref={iframeRef}
        ></iframe>
      )}
    </ShareRowContainer>
  )
}

const ShareRowContainer = styled.div(
  ({
    justifyAlignment,
    showPrintAndDownload,
    isVideoMedia,
    modalShareButton,
    position,
  }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: ${justifyAlignment};
    align-items: center;
    width: 100%;
    left: ${isVideoMedia || !showPrintAndDownload || !!!modalShareButton
      ? "0px"
      : "calc(-50% + 175px)"};
    top: ${position === "top" ? "-20" : position === "bottom" ? "30" : "0"}px;

    .print-frame {
      display: none;
    }

    ${mq.tabletNavigationMenu`
      top: ${position === "top" && "-30"}px;
    `}

    ${mq.tabletChapterSelector`
      width: 70%;
    `}
  `,
)

const ShareButtonContainer = styled.div`
  position: relative;
  width: 70px;
  text-align: center;
`

const IconContainer = styled.div(
  (props) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: ${props.showPrintAndDownload ? "350px" : "min-content"};

    a {
      text-decoration-line: none;
      :link {
        color: ${darkText};
      }
      :visited {
        color: ${darkText};
      }
    }
    img {
      max-width: 45px;
      margin-bottom: 20px;
    }
  `,
)

const ShareMenuContainer = styled.div(
  (props) => css`
    width: 100vw;
    position: ${props.isMobile ? "fixed" : "absolute"};
    bottom: 0px;
    left: 0px;
    background: ${shareMenuBackground};
    z-index: 100000;
    transition: all 0.2s linear;
    height: ${props.shareOptionsOpen ? "210px" : "0px"};

    .menu-item {
      &:hover {
        background: ${shareMenuItemHover};
      }
    }
    .no-decoration {
      color: ${darkText};
      text-decoration: none;
    }

    .top-border {
      border-top: 1px solid ${shareMenuItemBorder} !important;
    }

    .bolder-text {
      font-size: 18px;
      font-weight: bold;
    }

    .menu-item {
      height: 70px;
      padding: 0 1.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${shareMenuItemBorder};
      cursor: pointer;

      &:last-child {
        border: 0;
      }

      img {
        max-height: 30px;
        max-width: 30px;
      }
      svg {
        max-height: 13px;
        max-width: 13px;
      }
    }

    ${mq.tabletTopicDetail`
    display: ${props.shareOptionsOpen ? "block" : "none"};
    width: 240px;
    left: -260px;
    bottom: -150px;
    box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.75);
    z-index: 1003;
    &:after {
      content: "";
      position: absolute;
      left: 239px;
      bottom: 190px;
      border-left: 10px solid ${shareMenuBackground};
      border-right: 10px solid transparent;
      border-top: 10px solid ${shareMenuBackground};
      border-bottom: 10px solid transparent;
      box-shadow: 10px 10px 15px -13px rgba(0,0,0,0.75);
      filter:drop-shadow(2px 3px 2px #cfcfcf);
      z-index: 1002;
    }
    ${
      props.modalShareButton &&
      `right: calc(50% - 315px);
       left: unset;
       bottom: 75px;
       &:after {
         left: unset;
         bottom: unset;
       }
      `
    }
    ${
      props.isVideoMedia &&
      `right: calc(50% - 315px);
       left: unset;
       bottom: 75px;
       &:after {
         left: unset;
         bottom: unset;
       }
      `
    }
  `}

    ${mq.tabletExplorer`
    ${
      props.modalShareButton &&
      `right: calc(50% - 315px);
       left: -50px;
       bottom: 100px;
       &:after {
         left: unset;
         bottom: unset;
       }
      `
    }
    ${
      props.isVideoMedia &&
      `right: calc(50% - 315px);
       left: -50px;
       bottom: 100px;
       &:after {
         left: unset;
         bottom: unset;
       }
      `
    }
  `}

  ${mq.desktopStandard`
    ${
      props.isVideoMedia &&
      `right: calc(50% - 315px);
        bottom:65px;
      `
    }
    ${
      props.modalShareButton &&
      `right: calc(50% - 150px);
       left: unset;
       bottom: 110px;
       &:after {
         left: unset;
         bottom: unset;
       }
      `
    }
  `}
  `,
)
