import { css } from "styled-components"
import { breakpoints } from "./app_consts"
import { appColors } from "./app_colors"

const { whiteText, hoverChapterSelectorCardBorder } = appColors

export const generateMediaQueries = () => {
  const breakpointNames = Object.keys(breakpoints)
  return breakpointNames.reduce((acc, breakpoint) => {
    const size = breakpoints[breakpoint]
    return {
      ...acc,
      [breakpoint]: (...args) => css`
        @media all and (min-width: ${size}px) {
          ${css(...args)};
        }
      `,
    }
  }, {})
}

export const mq = generateMediaQueries()

export const sharedStyles = {
  buttonStyler: (bgColor, accentColor, lightTheme) => {
    return css`
      width: 220px;
      min-height: 55px;
      border-radius: 4px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${accentColor};
      border: 2px solid;
      border-color: ${accentColor};
      background-color: ${bgColor};
      transition: all 0.25s;

      &:hover {
        cursor: pointer;
        color: ${lightTheme ? bgColor : accentColor};
        border-color: ${lightTheme ? bgColor : accentColor};
        background-color: ${lightTheme ? accentColor : bgColor};
      }

      ${mq.desktopStandard`
        width: 200px;
        min-height: 45px;
      `}
    `
  },
  scalingFontSize: (minFontSize, maxFontSize) => {
    // input should be in numbers and the units are pixels
    const diff = maxFontSize - minFontSize

    return `
      --window-size: calc(100vw - 320px);
      font-size: calc(${minFontSize}px + ${diff} * (var(--window-size)/1120));
    `
  },
  handleMobileHover: (isMobile, themeColor) => {
    return `
      .selector-card {
        &${isMobile ? ".highlighted" : ":hover"} {
          background-color: ${themeColor};
          border-color: ${hoverChapterSelectorCardBorder};
          color: ${whiteText};
          height: 270px;
          .header {
            img {
              display: block;
              opacity: 1;
              height: 53px;
            }
            .bp3-icon path {
              fill: ${whiteText};
            }
            p.chapter-number {
              color: ${whiteText};
              margin-top: 1em;
            }
          }
        }
      }
    `
  },
}
