import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import HeroFooter from "./hero_footer"
import SiteFooter from "./site_footer"
import { breakpoints } from "../../assets/app_consts"

const { tabletNavigationMenu } = breakpoints

const Footer = ({
  phoneNumber,
  footer,
  baseColor,
  email,
  footerText,
  windowSize,
  partnerName,
  isPublic,
}) => {
  const { footerLogo, privacyUrl, footerLogoAltText } = footer
  const isMobile = windowSize < tabletNavigationMenu
  return (
    <FooterContainer>
      <HeroFooter
        phoneNumber={phoneNumber}
        footer={footer}
        email={email}
        isMobile={isMobile}
      />
      <SiteFooter
        baseColor={baseColor}
        logo={footerLogo}
        altText={footerLogoAltText}
        partnershipText={footerText}
        privacyUrl={privacyUrl}
        partnerName={partnerName}
        isPublic={isPublic}
      />
    </FooterContainer>
  )
}

Footer.propTypes = {
  phoneNumber: PropTypes.string,
  footer: PropTypes.object.isRequired,
  baseColor: PropTypes.string.isRequired,
}

const FooterContainer = styled.section``

export default Footer
