import React, { Fragment } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Carousel from "nuka-carousel"
import { Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { appStrings } from "../../assets/app_strings"
import { appColors } from "../../assets/app_colors"
import { breakpoints } from "../../assets/app_consts"
import { mq, sharedStyles } from "../../assets/shared_styles"
import ChapterSelectorCard from "./chapter_selector_card"
import {
  calculateVisibleSelectorCards,
  generateUidsToRender,
} from "../../util/component_utils"
import { gtmId, gtmTypes } from "../../util/gtm_utils"

const { carouselControllerText, cardLabel } = appStrings.heroBanner
const { whiteText } = appColors
const { tabletStandard, tabletTopicDetail, tabletChapterSelector } = breakpoints
const {
  chapterCarouselControlRight: controlRight,
  chapterCarouselControlLeft: controlLeft,
} = gtmTypes
const { handleMobileHover } = sharedStyles

class ChapterSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slideIndex: 0,
      isMobileBrowser: null,
    }
  }

  componentDidMount() {
    const isMobileBrowser = /Mobi|Android/i.test(navigator.userAgent)

    this.setState({ isMobileBrowser })
  }

  componentDidUpdate(nextProps) {
    const { windowSize: currentWindowSize } = this.props
    const { windowSize: nextWindowSize } = nextProps
    const isMobileBrowser = /Mobi|Android/i.test(navigator.userAgent)

    if (
      nextWindowSize > currentWindowSize &&
      nextWindowSize > tabletTopicDetail
    ) {
      this.setState({ slideIndex: this.state.slideIndex + 1, isMobileBrowser })
    } else if (
      nextWindowSize < currentWindowSize &&
      nextWindowSize < tabletTopicDetail
    ) {
      this.setState({ slideIndex: this.state.slideIndex - 1, isMobileBrowser })
    }
  }

  setSlideIndex = (slideIndex, uidsToRender) => {
    const { windowSize } = this.props
    const maxSlideIndex = uidsToRender.length - 1

    if (windowSize > tabletTopicDetail) {
      const newSlideIndex = slideIndex - 1 < 0 ? maxSlideIndex : slideIndex - 1
      this.setState({ slideIndex: newSlideIndex })
    } else {
      this.setState({ slideIndex })
    }
  }

  renderCarouselController = (isLargerThanTablet, isAdvancedTopic) => (
    <div className="carousel-controller">
      <span className="medium">{carouselControllerText(isAdvancedTopic)}</span>
    </div>
  )

  renderCards = uidsToRender => {
    const { getDocument, themeColor, topicTitle, isAdvancedTopic } = this.props

    if (!uidsToRender) {
      return []
    }

    return uidsToRender.map((c, i) => {
      const { chapter: chapterUid } = c
      const chapter = getDocument("chapters", "uid", chapterUid)
      const { number } = chapter
      const cardNumberText = cardLabel(number, isAdvancedTopic)
      const isHighlighted = this.state.slideIndex === i

      return (
        <ChapterSelectorCard
          key={chapterUid}
          chapter={chapter}
          themeColor={themeColor}
          topicTitle={topicTitle}
          isHighlighted={isHighlighted}
          cardNumberText={cardNumberText}
        />
      )
    })
  }

  renderCarousel = () => {
    const { chapterUids, windowSize, isAdvancedTopic } = this.props

    const isLargerThanTablet = windowSize > tabletStandard
    const visibleCards = calculateVisibleSelectorCards(windowSize)
    const iconSize = isLargerThanTablet ? 24 : 16
    const useCarousel =
      chapterUids.length > 3
        ? true
        : chapterUids.length === 3 && windowSize < tabletChapterSelector
    const uidsToRender = useCarousel
      ? generateUidsToRender(chapterUids, visibleCards)
      : chapterUids

    return useCarousel ? (
      <Fragment>
        {this.renderCarouselController(isLargerThanTablet, isAdvancedTopic)}
        <Carousel
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide}>
              <Icon
                className="no-child-pointer-events"
                icon={IconNames.CHEVRON_LEFT}
                color={whiteText}
                iconSize={iconSize}
                id={gtmId(controlLeft, "move-chapter-carousel-left")}
              />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide}>
              <Icon
                className="no-child-pointer-events"
                onClick={() => nextSlide()}
                icon={IconNames.CHEVRON_RIGHT}
                color={whiteText}
                iconSize={iconSize}
                id={gtmId(controlRight, "move-chapter-carousel-right")}
              />
            </button>
          )}
          slidesToShow={visibleCards}
          slideOffset={0.7}
          wrapAround={true}
          initialSlideHeight={270}
          initialSlideWidth={225}
          cellAlign={windowSize > tabletTopicDetail ? "left" : "center"}
          cellSpacing={5}
          slideIndex={0}
          frameOverflow={"hidden"}
          afterSlide={slideIndex =>
            this.setSlideIndex(slideIndex, uidsToRender)
          }
          speed={300}
        >
          {this.renderCards(uidsToRender)}
        </Carousel>
      </Fragment>
    ) : (
      <div className="chapter-list">{this.renderCards(uidsToRender)}</div>
    )
  }

  render() {
    const { chapterUids, themeColor } = this.props
    const { isMobileBrowser } = this.state
    const chapterCount = chapterUids ? chapterUids.length : 0

    return (
      <ChapterSelectorContainer
        chapterCount={chapterCount}
        hoverColor={themeColor}
        isMobileBrowser={isMobileBrowser}
      >
        {this.renderCarousel()}
      </ChapterSelectorContainer>
    )
  }
}

ChapterSelector.propTypes = {
  chapterUids: PropTypes.array,
  themeColor: PropTypes.string,
}

const ChapterSelectorContainer = styled.div`
  // small phones+
  width: 100%;
  z-index: 10;
  position: absolute;
  top: ${props => (props.chapterCount > 3 ? 370 : 370 + 25.5)}px;
  right: 0;

  ${props => handleMobileHover(props.isMobileBrowser, props.hoverColor)}
  button {
    background: transparent;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .carousel-controller {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    margin-bottom: 1em;
    font-size: 0.875em;

    span {
      color: ${whiteText};
      margin-right: 5rem;
    }
  }
  .slider {
    height: 270px !important;

    &:focus {
      outline: 0;
    }
    &-slide {
      width: 225px !important;
    }
    &-control {
      &-centerright,
      &-centerleft {
        top: -20px !important;
      }
      &-centerleft {
        right: 40px !important;
        left: auto !important;
      }
      &-bottomcenter {
        display: none;
      }
    }
  }

  // normal phones+
  ${mq.newerPhoneStandard`
    .carousel-controller {
      font-size: 0.875em;
    }

    .chapter-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      div {
        margin-left: 10px;
      }

      div:first-child {
        margin: 0;
      }
    }
  `}

  ${mq.tabletTopicDetail`
    top: ${props => (props.chapterCount > 3 ? 475 : 475 + 32.5)}px;
  `}

  ${mq.tabletStandard`
    max-width: 900px;
  `};
`

export default ChapterSelector
