import React, { Fragment } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
//import { FixedBottom } from "react-fixed-bottom"
import { appColors } from "../../assets/app_colors"
import { mq } from "../../assets/shared_styles"
//import { breakpoints } from "../../assets/app_consts"
import NavigationTopicItem from "./navigation_topic_item"
import ContactUs from "../shared/contact_us"
import NavigationRow from "../shared/navigation_row"

//const { tabletNavigationMenu } = breakpoints
const { whiteText } = appColors

const NavigationMenu = ({
  baseColor,
  email,
  phoneNumber,
  iconOnClick,
  getDocument,
  topics,
  partnerLogo,
  isPublic,
  altText,
  //windowSize,
  isMenuOpen,
  partnerLogoLink,
  homeRoute,
  homeSlug
}) => {
  //const fixedOffset = windowSize >= tabletNavigationMenu
  //  ? 30
  //  : 30


  return (
    <NavigationMenuContainer baseColor={baseColor} isMenuOpen={isMenuOpen}>
      <NavigationRow
        partnerLogo={partnerLogo}
        altText={altText}
        iconOnClick={iconOnClick}
        baseColor={baseColor}
        isPublic={isPublic}
        phoneNumber={phoneNumber}
        isMenuOpen={isMenuOpen}
        partnerLogoLink={partnerLogoLink}
        email={email}
        homeRoute={homeRoute}
        homeSlug={homeSlug}
      />
      {isMenuOpen && (
        <Fragment>
          <div className="topic-wrapper">
            {topics.map(topic => (
              <NavigationTopicItem
                getDocument={getDocument}
                topic={topic}
                key={topic.uid}
                closeNav={iconOnClick}
              />
            ))}
          </div>
         {/* <FixedBottom offset={fixedOffset}>*/}
            <div className="contact-info">
              <ContactUs
                email={email}
                phoneNumber={phoneNumber}
                isFooter={false}
              />
            </div>
          {/*</FixedBottom>*/}
        </Fragment>
      )}
    </NavigationMenuContainer>
  )
}


NavigationMenu.propTypes = {
  baseColor: PropTypes.string,
  chapterUids: PropTypes.array,
  partnerEmail: PropTypes.string,
  phoneNumber: PropTypes.string,
  getDocument: PropTypes.func,
}

const NavigationMenuContainer = styled.div`
  /*position: fixed;*/
  z-index: 100;
  width: 100%;
  height: ${props => props.isMenuOpen ? "100%" : "80px"};
  background: ${props => props.baseColor};
  color: ${whiteText};
  overflow-x: hidden;

  > * {
    overflow-x: hidden;
  }

  .topic-wrapper {
    height: 100%;
    max-height: calc(100vh - 166px);
    margin: 5rem 1.250rem 0 1.250rem;
    padding: 2.5rem 1.250rem 1.750rem 1.250rem;

    overflow-y: auto;
  }

  .contact-info {
    text-align: center;
    /*margin: 46px 0;*/
    margin-top: 40px;
    bottom: 40px;
    position: relative;
    /*position: absolute;
    bottom: 28px;
    right: 30px;*/
  }

  ${mq.tabletStandard`
    height: ${props => props.isMenuOpen ? "100%" : "90px"};
    .topic-wrapper {
      margin: 5rem 4.688rem 0 4.688rem;
      padding: 2.5rem 4.688rem 0 4.688rem;
    }
  `}
`

export default NavigationMenu
