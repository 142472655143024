import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { appColors } from "../../assets/app_colors"
import { mq } from "../../assets/shared_styles"
import { appStrings } from "../../assets/app_strings"
import missing from "../../assets/static_images/404_graphic.png"
import { useHistory } from "react-router"

const { darkText } = appColors
const { header, subheader, body, homePage } = appStrings.missingPage

const NotFoundHeroBanner = ({ blockColor, homeRoute }) => {
  const history = useHistory()
  const handleHomeClick = () => {
    history.push(homeRoute + window.location.search)
  }

  return (
    <NotFoundHeroBannerContainer blockColor={blockColor}>
      <div className="header-container">
        <h3 className="bold-header">{header}</h3>
        <p className="emphatic-text">{subheader}</p>
        <p>
          {body}
          <span className="home-link" onClick={handleHomeClick}>
            {homePage}
          </span>
        </p>
      </div>
      <img src={missing} alt={""} />
    </NotFoundHeroBannerContainer>
  )
}

NotFoundHeroBanner.propTypes = {
  blockColor: PropTypes.string,
}

const NotFoundHeroBannerContainer = styled.section`
  width: 100%;
  padding: 0.5rem 2rem 2.125rem;
  color: ${darkText};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;

  .home-link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  .header-container {
    width: 100%;
    max-width: 475px;
    h3 {
      text-transform: uppercase;
      font-size: 2.75em;
      margin-bottom: 2.5rem;

      &:after {
        content: "";
        display: block;
        height: 5px;
        width: 90px;
        background: ${props => props.blockColor};
      }
    }
    p {
      &.emphatic-text {
        font-size: 1.125em;
        font-weight: bold;
      }
    }
  }
  img {
    max-height: 380px;
  }

  ${mq.desktopStandard`
    height: 660px;
    padding: 4rem 9rem 3rem 10rem;
    flex-direction: row;
    justify-content: space-between;

    .header-container {
      width: 45%;
      max-width: 475px;

      h3 {
        font-size: 5em;
      }
    }

    img {
      height: 100%;
      max-height: none;
    }
  `}
`

export default NotFoundHeroBanner
