const appStrings = {
  heroBanner: {
    carouselControllerText: (isAdvancedTopic) => isAdvancedTopic ? "Explore by Topic" : "Explore by Chapter",
    cardLabel: (chapterNumber, isAdvancedTopic) =>  isAdvancedTopic ? `` :`Chapter ${chapterNumber}`,
  },
  explorer: {
    header: "Start Exploring Today.",
    subheader: "Here's some featured content we think you'll be interested in.",
    playVideo: "Play Video ›",
    showMore: "Show More ›",
    viewChapter: (isAdvancedTopic) => isAdvancedTopic ? "View the Topic ›" : "View the Chapter ›",
    cardLabel: (chapterNumber, isAdvancedTopic) =>  isAdvancedTopic ? `` :`Chapter ${chapterNumber}`,
  },
  navigationMenu: {
    viewTopic: topicTitle => `View ${topicTitle} Overview`,
    callToday: "Call today! ",
  },
    heroFooter: {
    call: "Call ",
    orEmail: " or email us at ",
    noPhoneNumber: "Email us at "
  },
  mediaModal: {
    shareButton: "Share ›",
    share: "Share:",
    copyLink: "Copy Link",
    emailLink: "Email Link",
    linkCopied: "Link Copied",
    iosAlert: "This PDF will open in a new page. From there, you may use your phone's 'Share' button to print this PDF.",
    defaultAlert: "Your device will download or open this PDF in a new window.  You may then use your browser's built in print button.",
    printFailure: "Unable to print from this device or browser."
  },
  siteFooter: {
    mFinCopyright: (partnerName, showPartnerName) => {
      const currentYear = new Date().getFullYear()
      return showPartnerName
        ? `M Financial Group | ${partnerName} © ${currentYear}`
        : `M Financial Group © ${currentYear}`
    },
  },
  missingPage: {
    header: "Oops!",
    subheader: "Looks like this page has gone missing.",
    body:
      "Try going back to the previous page, or visit the ",
    homePage: "home page."
  },
}

export { appStrings }
